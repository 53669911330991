/**
 * @summary ConnectionCollectionPage.js
 * @file parent component for the ConnectionCollectionGrid
 * @returns {JSX}
 * @usedBy tableviewLayout.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import ConnectionCollectionGrid from './ConnectionCollectionGrid';
import PropTypes from 'prop-types';

const NodeCollectionPage = ({ headers, setSelectedElements, selectedElements }) => {
  // Forces re-render //
  const [refresh, setRefresh] = useState(false);
  return (
    <div>
      <ConnectionCollectionGrid setRefresh={setRefresh} refresh={refresh} headers={headers} selectedElements={selectedElements} setSelectedElements={setSelectedElements}/>
    </div>
  );
};

NodeCollectionPage.propTypes = {
  headers: PropTypes.object,
  setSelectedElements: PropTypes.func,
  selectedElements: PropTypes.object,
};

export default NodeCollectionPage;
