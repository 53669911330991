/**
 * @summary NodeForm.js
 * @file form component for new/edit nodes
 * @returns {JSX}
 * @usedBy routes.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from '../../../store/store';
import { hydrateProject } from '../../../store/ProjectSetup/ProjectSetupActions';
import {
  getNode,
  editNode,
  createNode,
  setNewNode,
  getNodeCopy
} from '../../../store/nodes/NodeActions';
import { editMedia } from 'store/media/MediaActions';
import NodeFormHeader from './NodeFormHeader';
import Accordion from '../../../shared/accordian/Wrapper';
import Panel from '../../../shared/accordian/Panel';
import AttributeSettings from './AttributeSettings';
import NodeConnectionsSettings from './NodeConnectionsSettings';
import NodeMediaGrid from './NodeMediaGrid';
import { useNavigate } from 'react-router-dom';
import Memberships from './Memberships';

import validator from 'store/validation/validator';
import newNodeSchema from 'store/validation/newNode.schema';
import ValidationMsg from 'store/validation/ValidationMsg';

const NodeForm = () => {
  const node = useSelector((state) => state.nodeReducer.selectedData[0]);
  const userObj = useSelector((state) => state.authReducer.userObj);
  const selectedBranch = useSelector((state) => state.authReducer.userObj.selectedBranch[0]);
  const selectedProject = useSelector((state) => state.authReducer.userObj.selectedProject[0]);
  const nodeArray = useSelector((state) => {
    const nodes = { ...state.nodeReducer };
    delete nodes.selectedData;
    return Object.values(nodes);
  }, shallowEqual)

  const navigate = useNavigate();
  const [nodeDetails, setNodeDetails] = useState([]);
  const [contentMetadata, setContentMetadata] = useState([]);
  const [taxonomiesDimensions, setTaxonomiesDimensions] = useState([]);
  const [accessibilityFlags, setAccessibilityFlags] = useState([]);
  const [nodeName, setNodeName] = useState('');
  const [nodeKey, setNodeKey] = useState('');
  const [nodeDesc, setNodeDesc] = useState('');
  const [nodeMediaIds, setNodeMediaIds] = useState([]);
  const projectChange = useRef(selectedProject);
  const branchChange = useRef(selectedBranch);
  const [refreshMem, setRefreshMem] = useState(false);
  const [cloneAttrsLoaded, setCloneAttrsLoaded] = useState(false);
  const [selectedClone, setSelectedClone] = useState(null);
  const [cloneConfirmationVisible, setCloneConfirmationVisible] =
    useState(false);
  const [editedMedia, setEditedMedia] = useState([]);

  const fullHref = window.location.pathname;
  const lastHref = fullHref.substring(fullHref.lastIndexOf('/') + 1);

  const handleCloneSelect = (e) => {
    store.dispatch(
      getNodeCopy(
        userObj.selectedProject[0].id,
        userObj.selectedBranch[0].id,
        selectedClone.id
      )
    );
    setCloneConfirmationVisible(false);
  };

  const updateMedia = (newMedia) => {
    setNodeMediaIds([...nodeMediaIds, newMedia]);
  };

  const loadProject = () => {
    store.dispatch(hydrateProject(userObj));
  };

  useEffect(() => {
    //User changes the profile project and saves we send them to tableview
    if (selectedProject !== projectChange.current) {
      toast.error(`${nodeKey} does not exist on ${selectedProject.name}`);
      navigate('/tableview');
    } else if (selectedBranch !== branchChange.current) {
      toast.error(`${nodeKey} does not exist on ${selectedBranch.name}`);
      navigate('/tableview');
    }
  }, [selectedProject, selectedBranch]);

  useEffect(() => {
    loadProject();
    
    if (node?.id > 0 && lastHref === 'edit') {
      store.dispatch(
        getNode(
          userObj.selectedProject[0].id,
          userObj.selectedBranch[0].id,
          node?.id
        )
      );
      setNodeName(node?.name);
      setNodeDesc(node?.description);
      setNodeKey(node?.nodeKey);
    } else {
      store.dispatch(setNewNode());
    }
  }, []);
  // Iterating over arrayOfAttrs with num var that is passed down from incrementNumForAttrs //
  // Uses keys of attrVar and jsonAttr to fetch corresponding values for the forEach and .push //
  // Creates a set of settings for each category (nodeDetails, contentMetadata, taxonomiesDimensions, accessibilityFlags) //
  const pushAttrsToJson = (arrayOfAttrs, num) => {
    arrayOfAttrs[num]?.attrVar.forEach((attr) => {
      const validAttrs = attr.selectedValue.filter(Boolean);
      arrayOfAttrs[num]?.jsonAttr.push({
        attrId: attr.attributeId,
        nodeAttrId: attr.nodeAttrId,
        value: validAttrs.map((val) => {
          return {
            id: attr.metaDataId.length ? val.id : undefined,
            nodeValueId: val.nodeValueId,
            value: val.name,
            nodeAttrId: val.nodeAttrId
          };
        })
      });
    });
    num++;
  };

  const incrementNumForAttrs = (arrayOfAttrs) => {
    let num = 0;
    while (num < 4) {
      pushAttrsToJson(arrayOfAttrs, num);
      num++;
    }
  };

  const onSave = () => {
    if (editedMedia.length === 1) store.dispatch(editMedia(editedMedia))

    const json = {
      id: selectedClone === null ? node?.id : -1,
      nodeKey: nodeKey,
      description: nodeDesc,
      status: node.status,
      source: node.source || 'Page Created',
      name: nodeName,
      nodeMediaIds,
      settings: {
        details: [],
        metadata: [],
        taxonomies: [],
        flags: []
      }
    };
    // Array of all the attributes within json.settings //
    const arrayOfAttrs = [
      {
        attrVar: nodeDetails,
        jsonAttr: json.settings.details,
        test: 'node'
      },
      {
        attrVar: contentMetadata,
        jsonAttr: json.settings.metadata,
        test: 'metadata'
      },
      {
        attrVar: taxonomiesDimensions,
        jsonAttr: json.settings.taxonomies,
        test: 'taxonomies'
      },
      {
        attrVar: accessibilityFlags,
        jsonAttr: json.settings.flags,
        test: 'flags'
      }
    ];

    incrementNumForAttrs(arrayOfAttrs);

    json.projectId = userObj.selectedProject[0].id;
    json.branchId = userObj.selectedBranch[0].id;

    // VALIDATOR CONFIRMS WHETHER SCHEMA MATCHES JSON (NODE) SAVE
    validator(newNodeSchema, json).then((resp) => {
      if (resp) {
        if (json?.id < 1) {
          store
            .dispatch(createNode(json))
            .then((res) => {
              if (res.status === 201) {
                if (res.data.message_type !== 'success') {
                  toast.error(
                    'An unexpected error occurred while creating Node: ${node.name}'
                  );
                } else {
                  toast.success(`Node: ${json.name} Created!`);
                }
              }
            })
            .catch((errors) => {
              // RETURNS ANY ERRORS FROM THE BACKENDF THAT PREVENT SAVING
              const errorMessages = errors?.response?.data;
              toast.error(
                <ValidationMsg
                  errorsArray={errorMessages}
                  message={errors.message}
                />,
                { autoClose: 5000 }
              );
            });
        } else {
          store
            .dispatch(editNode(json))
            .then((res) => {
              if (res.status === 200) {
                if (res.data.message_type !== 'success') {
                  if (
                    res.data === 'this Node is not checked out on this Branch'
                  ) {
                    toast.error(
                      `Node: ${node.name} is not checked out on Branch: ${userObj.selectedBranch[0].name}`
                    );
                  } else if (
                    res.data === 'current User does not own this branch'
                  ) {
                    toast.error(
                      `${userObj.screenName} does not own this Branch: ${userObj.selectedBranch[0].name}`
                    );
                  } else if (res.data === 'branch not in draft status') {
                    toast.error(
                      `Branch ${userObj.selectedBranch[0].name} not in "draft" status`
                    );
                  } else {
                    toast.error(
                      'An unexpected error occurred while updating Node: ${node.name}'
                    );
                  }
                }
              } else {
                toast.success(`Node: ${json.name} Updated`);
              }
            })
            .catch((errors) => {
              // RETURNS ANY ERRORS FROM THE BACKENDF THAT PREVENT SAVING
              const errorMessages = errors.response.data;
              toast.error(
                <ValidationMsg
                  errorsArray={errorMessages}
                  message={errors.data}
                />,
                { autoClose: 5000 }
              );
            });
        }
      }
    });
  };

  return (
    <>
      {/* zIndex is added for Kendo Grid Sorting Menu overlapping header*/}
      <div style={{ zIndex: 2 }}>
        <NodeFormHeader
          node={node}
          nodeName={nodeName}
          nodeKey={nodeKey}
          setNodeKey={setNodeKey}
          setNodeName={setNodeName}
          onSave={onSave}
          nodeDesc={nodeDesc}
          setNodeDesc={setNodeDesc}
          lastHref={lastHref}
        />
      </div>
      <Accordion>
        <div style={{ zIndex: 1 }}>
          <Panel
            title="Attribute Settings"
            help_code={'ATTRIBUTE'}
            panel_type={'attribute_settings'}
            body={
              <AttributeSettings
                userObj={userObj}
                node={node}
                nodeDetails={nodeDetails}
                setNodeDetails={setNodeDetails}
                taxonomiesDimensions={taxonomiesDimensions}
                setTaxonomiesDimensions={setTaxonomiesDimensions}
                accessibilityFlags={accessibilityFlags}
                setAccessibilityFlags={setAccessibilityFlags}
                contentMetadata={contentMetadata}
                setContentMetadata={setContentMetadata}
                handleCloneSelect={handleCloneSelect}
                cloneConfirmationVisible={cloneConfirmationVisible}
                setCloneConfirmationVisible={setCloneConfirmationVisible}
                selectedClone={selectedClone}
                setSelectedClone={setSelectedClone}
                setCloneAttrsLoaded={setCloneAttrsLoaded}
                cloneAttrsLoaded={cloneAttrsLoaded}
                lastHref={lastHref}
                nodeArray={nodeArray}
              />
            }
          />
          <Panel
            title="Acknowledgements"
            help_code={'ACKNOWLEDGEMENTS'}
            panel_type={'achknowledgements'}
            body={<h1>not yet implemented</h1>}
          />
          <Panel
            title="Connections"
            help_code={'CONNECTIONS'}
            panel_type={'connections'}
            body={<NodeConnectionsSettings setRefresh={setRefreshMem} />}
          />
          <Panel
            title="Media"
            help_code={'MEDIA'}
            panel_type={'media'}
            body={
              <NodeMediaGrid
                updateMedia={updateMedia}
                setRefresh={setRefreshMem}
                setEditedMedia={setEditedMedia}
              />
            }
          />
          <Panel
            title="Memberships"
            help_code={'MEMBERSHIPS'}
            panel_type={'memberships'}
            body={
              <Memberships refresh={refreshMem} setRefresh={setRefreshMem} />
            }
          />
          <Panel
            title="Styles"
            help_code={'STYLES'}
            panel_type={'styles'}
            body={<h1>not yet implemented</h1>}
          />
        </div>
      </Accordion>
    </>
  );
};

export default NodeForm;
