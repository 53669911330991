/**
 * @summary CanvasSaveView.js
 * @file Modal that contains functionality for Saving Views. Opened through CanvasInterface.js.
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import '../canvasPage.scss';
import { toast } from 'react-toastify';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Modal from 'react-bootstrap/Modal';
import { createView, editView } from 'store/views/ViewActions';
import store from 'store/store';

//TODO: NEED TO FIGURE OUT WHAT TO DO IF THINGS DONT EXIST ON A BRANCH AND TRY TO LOAD THINGS FROM A BRANCH WHERE THEY DID EXIST 4/5/2023

const CanvasSaveView = ({
  showViewSaveMenu,
  onShowViewSaveMenu,
  diagramAccess,
  setRenderMapData
}) => {
  const selectedBranch = useSelector((state) => state.authReducer.userObj.selectedBranch[0]);
  const selectedProject = useSelector((state) => state.authReducer.userObj.selectedProject[0]);
  const views = useSelector((state) => state.viewReducer.selectedData);
  const publishedBranch = useSelector((state) =>
    state.branchesDetailsReducer.activeBranches?.find(
      (branch) => branch.branchStatus === 'published'
    )
  );

  const [viewName, setViewName] = useState('');

  let savedMapData = {
    id: views?.id ? views?.id : null,
    projectId: selectedProject.id,
    branchId: selectedBranch.id,
    parentSource: views?.name ? views.name : selectedBranch.name,
    parentSourceType: views?.name ? 'view' : 'branch',
    savedViewName: viewName,
    savedNodes: [],
    savedNeighborhoods: [],
    savedConnections: [],
    themeData: {
      nodeThemeData: []
    }
  };

  if (diagramAccess) {
    diagramAccess.model.nodeDataArray.forEach((element) => {
      if (element.category === 'Super') {
        element.id = element.key;
        if (element.visible === true) {
          if (element.checkedOutBy !== 'Open') {
            element.branchId = selectedBranch.id;
          } else {
            element.branchId = publishedBranch.id;
          }
          savedMapData.savedNeighborhoods.push(element);
        }
      } else if(element.category !== 'Super') {
        element.id = element.key;
        if (element.visible === true) {
          savedMapData.savedNodes.push(element);
        }
        if (element.checkedOutBy !== 'Open') {
          element.branchId = selectedBranch.id;
        } else {
          element.branchId = publishedBranch.id;
        }
      }
    });
    diagramAccess.model.linkDataArray.forEach((element) => {
      savedMapData.savedConnections.push(element);
      if (element.checkedOutBy !== 'Open') {
        element.branchId = selectedBranch.id;
      } else {
        element.branchId = publishedBranch.id;
      }
    });
  }

  const gridRef = useRef();

  const nodeCols = [
    { field: 'title', headerName: 'Node Name' },
    { field: 'nodeKey', headerName: 'Node Key' },
    { field: 'description', headerName: 'Description' }
  ];

  const saveView = (isNew) => {
    if (isNew) {
      store
        .dispatch(createView(savedMapData))
        .then((res) => {
          if (res.status === 201) {
            if (res.data.message_type !== 'success') {
              toast.error(
                `An unexpected error occurred while creating View: ${savedMapData.name}`
              );
            } else {
              toast.success(`View Created!`);
              setRenderMapData((mapData) => ({
                ...mapData,
                viewName,
                parentSource: savedMapData.parentSource,
                parentSourceType: savedMapData.parentSourceType
              }));
            }
          }
        })
        .catch((errors) => {
          // NEED TO ADD TOASTIFY ERROR MESSAGING - LEAVE CODE HERE FOR NOW
          toast.error('Unable to Save View.');
          // const errorMessages = errors.response.data
          // toast.error(<ValidationMsg errorsArray={errorMessages} message={errors} />, {autoClose:5000});
        });
    } else {
      
      savedMapData.parentSourceType = 'view';
      store
        .dispatch(editView(savedMapData))
        .then((res) => {
          if (res.status === 201) {
            if (res.data.message_type !== 'success') {
              toast.error(
                `An unexpected error occurred while overwriting View: ${savedMapData.name}`
              );
            } else {
              toast.success(`View overwritten!`);
              setRenderMapData((mapData) => ({
                ...mapData,
                viewName,
                parentSource: savedMapData.parentSource,
                parentSourceType: savedMapData.parentSourceType
              }));
            }
          }
        })
        .catch((errors) => {
          // NEED TO ADD TOASTIFY ERROR MESSAGING - LEAVE CODE HERE FOR NOW
          // const errorMessages = errors.response.data
          // toast.error(<ValidationMsg errorsArray={errorMessages} message={errors} />, {autoClose:5000});
        });
    }
    setViewName('');
    onShowViewSaveMenu(false);
  };
  return (
    <>
      <Modal show={showViewSaveMenu} backdrop="static" centered size="lg">
        <Modal.Header>
          <Modal.Title>
            <div className='view-save-modal-header'>
              Save this Map as a Loadable View
            </div>
          </Modal.Title>
          <div
            className="bi bi-x submenu-close-x"
            onClick={() => onShowViewSaveMenu(false)}
          ></div>
        </Modal.Header>
        <Modal.Body>
          <div className='view-save-modal-body'>
            <div className="form-group row  d-flex">
              <div className='row'>
              <div className='col-2'>
                <label className="justify-center">View Name:</label>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  value={viewName}
                  onChange={(e) => setViewName(e.target.value)}
                  className="form-control form-control-sm modal-input"
                  style={{ width: '100%'}}
                  required
                />
              </div>
              </div>
            </div>
            <div className="divider p-1"></div>
            <div
              className="ag-theme-alpine"
              style={{ height: 300, width: '100%', position: 'relative' }}
            >
              Filtered Nodes in View
              <AgGridReact
                ref={gridRef}
                columnDefs={nodeCols}
                rowData={savedMapData.savedNodes}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className='container'>
          <div className='row'>
            <div className='bootstrap-modal-footer-button-alignment'>

              <button
                className="btn btn-primary btn-sm text-white bootstrap-footer-button-stylization"
                disabled={viewName.length < 1}
                onClick={() => {
                  saveView(true);
                }}
              >
                Save New View
              </button>
              {views?.id ? (
                <button
                  className="btn btn-primary btn-sm text-white bootstrap-footer-button-stylization"
                  disabled={viewName.length < 1}
                  onClick={() => {
                    saveView(false);
                  }}
                >
                  Overwrite View
                </button>
              ) : null}
            </div>
          </div>
        </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

CanvasSaveView.propTypes = {
  showViewSaveMenu: PropTypes.bool,
  onShowViewSaveMenu: PropTypes.func,
  diagramAccess: PropTypes.object,
  setRenderMapData: PropTypes.func
};

export default CanvasSaveView;
