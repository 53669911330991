/**
 * @summary NeighborhoodForm.js
 * @file neighborhood form component for creating, editing, or deleting a neighborhood
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

/* eslint-disable react-hooks/exhaustive-deps */
// TODO: CLEAN UP AND MOVE EVERYTHING TO REDUX AND USE SELECTORS

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from '../../../store/store';
import { hydrateProject } from '../../../store/ProjectSetup/ProjectSetupActions';
import NeighborhoodFormHeader from './NeighborhoodFormHeader';
import Accordion from '../../../shared/accordian/Wrapper';
import Panel from '../../../shared/accordian/Panel';
import NeighborhoodNodesSettings from './NeighborhoodNodesSettings';
import NeighborhoodAttributeSettings from './NeighborhoodAttributeSettings';
import LinkageLevelsSettings from './LinkageLevelsSettings';
import {
  createNeighborhood,
  editNeighborhood,
  getNeighborhood,
  getNeighborhoodCopy,
  updateSelectedLinkageLevelsData
} from 'store/neighborhoods/NeighborhoodActions';
import { editMedia } from 'store/media/MediaActions';
import NeighborhoodMediaGrid from './NeighborhoodMediaGrid';
import { useNavigate } from 'react-router-dom';

import validator from 'store/validation/validator';
import newNeighborhoodSchema from 'store/validation/newNeighborhood.schema';
import ValidationMsg from 'store/validation/ValidationMsg';

const messageOptions = {
  messsageType: {
    success: 'Success'
  },
  messages: {
    successOnDelete: 'Node Deleted',
    errorOnDelete: 'Unable to Delete Node',
    errorOnSave: 'Unable to Save Node',
    underConstruction: 'This Function is Under Construction'
  }
};

const NeighborhoodForm = () => {
  const userObj = useSelector(
    (state) => state.authReducer.userObj
  );
  const project = useSelector(
    (state) => state.projectSetupReducer
  );
  const neighborhood = useSelector(
    (state) => state.neighborhoodReducer.selectedData[0]
  );
  const selectedLinkageLevels = useSelector(
    (state) => state.neighborhoodReducer.selectedData[0]?.linkageLevels
  );
  const selectedProject = useSelector(
    (state) => state.authReducer.userObj.selectedProject[0]
  );
  const selectedBranch = useSelector(
    (state) => state.authReducer.userObj.selectedBranch[0]
  );

  const neighborhoodArray = useSelector((state) => {
    const neighborhoods = { ...state.neighborhoodReducer };
    delete neighborhoods?.selectedData;
    delete neighborhoods?.error;
    delete neighborhoods?.selectedNeighborhood;
    return Object.values(neighborhoods)
  }, shallowEqual);
  
  //get linkage level data fn call in LinkageLevelSettings
  // iterate thru and get each matching linkage level and the displaying data correctly
  //pass props to Form
  // take props and format to json to edit fn

  const navigate = useNavigate();
  const [neighborhoodDetails, setNeighborhoodDetails] = useState([]);
  const [contentMetadata, setContentMetadata] = useState([]);
  const [taxonomiesDimensions, setTaxonomiesDimensions] = useState([]);
  const [id, setId] = useState(neighborhood?.id);
  const [neighborhoodState, setNeighborhoodState] = useState({});
  const [neighborhoodName, setNeighborhoodName] = useState('');
  const [neighborhoodDesc, setNeighborhoodDesc] = useState('');
  const [neighborhoodEE, setNeighborhoodEE] = useState('');
  const [shortDesc, setShortDesc] = useState();
  const [longDesc, setLongDesc] = useState();
  const [neighborhoodMediaIds, setNeighborhoodMediaIds] = useState([]);
  const [linkageLevelMediaIds, setLinkageLevelMediaIds] = useState([]);
  const [, setRefresh] = useState(false);
  const projectChange = useRef(selectedProject);
  const branchChange = useRef(selectedBranch);
  const [selectedClone, setSelectedClone] = useState(null);
  const [cloneConfirmationVisible, setCloneConfirmationVisible] =
    useState(false);
  const [editedMedia, setEditedMedia] = useState([]);

  const fullHref = window.location.pathname;
  const lastHref = fullHref.substring(fullHref.lastIndexOf('/') + 1);

  const handleCloneSelect = (e) => {
    store.dispatch(
      getNeighborhoodCopy(
        userObj.selectedProject[0].id,
        userObj.selectedBranch[0].id,
        selectedClone.id
      )
    );
    setCloneConfirmationVisible(false);
  };

  const updateSelectedLinkageLevels = (data) => {
    store.dispatch(updateSelectedLinkageLevelsData(data));
  };

  const loadProject = () => {
    store.dispatch(hydrateProject(userObj));
  };

  const updateMedia = (newMedia) => {
    setNeighborhoodMediaIds([...neighborhoodMediaIds, newMedia]);
  };

  const updateLinkageLevelMedia = (newMedia, linkageLevelNeighId) => {
    setLinkageLevelMediaIds([
      ...linkageLevelMediaIds,
      { media_id: newMedia, linkageLevelNeighId }
    ]);
  };

  const updateLinkageLevels = (updatedObj) => {
    const copyData = [...selectedLinkageLevels];
    const idx = copyData.findIndex((ll) => ll.id === updatedObj.id);
    if (updatedObj.id > 0) {
      updatedObj.longDescription = copyData[idx].longDescription;
      updatedObj.shortDescription = copyData[idx].shortDescription;
      updatedObj.name = copyData[idx].linkageLevelName;
      copyData.splice(idx, 1, updatedObj);
    } else {
      const reidx = neighborhood.linkageLevels.findIndex(
        (ll) => ll.id === updatedObj.id
      );
      neighborhood.linkageLevels[reidx].shortDescription =
        copyData[idx].shortDescription;
      neighborhood.linkageLevels[reidx].longDescription =
        copyData[idx].longDescription;
      neighborhood.linkageLevels[reidx].name = copyData[idx].linkageLevelName;
      copyData.splice(idx, 1, neighborhood.linkageLevels[reidx]);
    }
    updateSelectedLinkageLevels(copyData);
  };

  useEffect(() => {
    loadProject();
    // gets nodes and linkage levels
    if (neighborhood?.id > 0 && lastHref === 'edit') {
      store.dispatch(
        getNeighborhood(
          userObj.selectedProject[0].id,
          userObj.selectedBranch[0].id,
          neighborhood?.id
        )
      );
      setNeighborhoodState(neighborhood);
      setNeighborhoodName(neighborhood?.name);
      setNeighborhoodDesc(neighborhood?.description);
      setNeighborhoodEE(neighborhood?.associated_ee);
    }
  }, []);

  useEffect(() => {
    //User changes the profile project and saves we send them to tableview
    if (selectedProject !== projectChange.current) {
      toast.error(
        `${neighborhoodName} does not exist on ${selectedProject.name}`
      );
      navigate('/tableview');
    } else if (selectedBranch !== branchChange.current) {
      toast.error(
        `${neighborhoodName} does not exist on ${selectedBranch.name}`
      );
      navigate('/tableview');
    }
  }, [selectedProject, selectedBranch]);

  const onSave = () => {
    if (editedMedia.length === 1) store.dispatch(editMedia(editedMedia))
    
    if (!neighborhoodState.checkedOutBy) {
      toast.error(`Cannot edit ${neighborhoodName}. Neighborhoods must be checked out.`)
      return
    }
    const json = {
      id: selectedClone === null || neighborhood?.id > -1 ? neighborhood?.id : -1,
      name: neighborhoodName,
      associated_ee: neighborhoodEE,
      description: neighborhoodDesc,
      nodes: neighborhood?.nodeIds,
      neighborhoodMediaIds,
      linkageLevelMediaIds,
      settings: {
        details: [],
        metadata: [],
        taxonomies: []
      },
      linkageLevels: []
    };
    selectedLinkageLevels?.forEach((attr) => {
      if (attr.id > 0 || attr.toSave) {
        json.linkageLevels.push({
          id: attr.id,
          name: attr.linkageLevelName,
          nodes: attr.nodes.map((val) =>
            val.nodeKey ? val.nodeKey : val.name
          ),
          neighborhood_id: attr.neighborhoodId,
          linkage_level_id: attr.linkageLevelId,
          long_description: attr.longDescription,
          short_description: attr.shortDescription,
          linkageLevelMedia: attr.linkageLevelMedia.map(
            (media) => media.mediacontentid
          )
        });
      }
    });

    neighborhoodDetails.forEach((attr) =>
      json.settings.details.push({
        attrId: attr.attributeId,
        neighborhoodAttrId: attr.neighborhoodAttrId,
        value: attr.selectedValue.map((val) => ({
          id: attr.metaDataId.length ? val.id : undefined,
          neighborhoodValueId: val.neighborhoodValueId,
          value: val.name,
          neighborhoodAttrId: val.neighborhoodAttrId
        }))
      })
    );

    contentMetadata.forEach((attr) =>
      json.settings.metadata.push({
        attrId: attr.attributeId,
        neighborhoodAttrId: attr.neighborhoodAttrId,
        value: attr.selectedValue.map((val) => ({
          id: attr.metaDataId.length ? val.id : undefined,
          neighborhoodValueId: val.neighborhoodValueId,
          value: val.name,
          neighborhoodAttrId: val.neighborhoodAttrId
        }))
      })
    );

    taxonomiesDimensions.forEach((attr) =>
      json.settings.taxonomies.push({
        attrId: attr.attributeId,
        neighborhoodAttrId: attr.neighborhoodAttrId,
        value: attr.selectedValue.map((val) => ({
          id: attr.metaDataId.length ? val.id : undefined,
          neighborhoodValueId: val.neighborhoodValueId,
          value: val.name,
          neighborhoodAttrId: val.neighborhoodAttrId
        }))
      })
    );

    json.projectId = userObj.selectedProject[0].id;
    json.branchId = userObj.selectedBranch[0].id;

    // VALIDATOR CONFIRMS WHETHER SCHEMA MATCHES JSON (NODE) SAVE
    validator(newNeighborhoodSchema, json).then((resp) => {
      if (resp) {
        
        if (json?.id < 1) {
          store
            .dispatch(createNeighborhood(json))
            .then((res) => {
              if (res.status === 201) {
                if (res.data.message_type !== 'success') {
                  toast.error(
                    `An unexpected error occurred while updating Neighborhood: ${json.name}`
                  );
                } else {
                  toast.success(`Neighborhood: ${json.name} Created!`);
                }
              }
            })
            .catch((errors) => {
              // RETURNS ANY ERRORS FROM THE BACKENDF THAT PREVENT SAVING
              const errorMessages = errors?.response?.data;
              toast.error(
                <ValidationMsg
                  errorsArray={errorMessages}
                  message={errors.message}
                />,
                { autoClose: 5000 }
              );
            });
        } else {
          store
            .dispatch(editNeighborhood(json))
            .then((res) => {
              if (res.status === 200) {
                if (res.data.message_type != 'success') {
                  if (
                    res.data ===
                    'this Neighborhood is not checked out on this Branch'
                  ) {
                    toast.error(
                      `Neighborhood: ${neighborhood?.name} is not checked out on Branch: ${userObj.selectedBranch[0].name}`
                    );
                  } else if (
                    res.data === 'current User does not own this branch'
                  ) {
                    toast.error(
                      `${userObj.screenName} does not own this Branch: ${userObj.selectedBranch[0].name}`
                    );
                  } else if (res.data === 'branch not in draft status') {
                    toast.error(
                      `Branch ${userObj.selectedBranch[0].name} not in "draft" status`
                    );
                  } else {
                    toast.error(
                      `An unexpected error occurred while updating Neighborhood: ${neighborhood?.name}`
                    );
                  }
                }
              } else {
                toast.success(`Neighborhood: ${neighborhood?.name} Updated`);
              }
            })
            .catch((errors) => {
              // RETURNS ANY ERRORS FROM THE BACKENDF THAT PREVENT SAVING
              const errorMessages = errors.response.data;
              toast.error(
                <ValidationMsg
                  errorsArray={errorMessages}
                  message={errors.message}
                />,
                { autoClose: 5000 }
              );
            });
        }
      }
    });
  };

  return (
    <>
      {/* zIndex is added for Kendo Grid Sorting Menu overlapping header*/}
      <div style={{ zIndex: 2 }}>
        <NeighborhoodFormHeader
          neighborhoodName={neighborhoodName}
          setNeighborhoodName={setNeighborhoodName}
          onSave={onSave}
          neighborhoodDesc={neighborhoodDesc}
          setNeighborhoodDesc={setNeighborhoodDesc}
          neighborhoodEE={neighborhoodEE}
          setNeighborhoodEE={setNeighborhoodEE}
          neighborhood={neighborhood}
        />
      </div>
      <div style={{ zIndex: 1 }}>
        <Accordion>
          <Panel
            title="Attribute Settings"
            help_code={'ATTRIBUTE'}
            panel_type={'attribute_settings'}
            body={
              <NeighborhoodAttributeSettings
                userObj={userObj}
                neighborhood={neighborhood}
                neighborhoodDetails={neighborhoodDetails}
                setNeighborhoodDetails={setNeighborhoodDetails}
                taxonomiesDimensions={taxonomiesDimensions}
                setTaxonomiesDimensions={setTaxonomiesDimensions}
                contentMetadata={contentMetadata}
                setContentMetadata={setContentMetadata}
                neighborhoodArray={neighborhoodArray}
                handleCloneSelect={handleCloneSelect}
                cloneConfirmationVisible={cloneConfirmationVisible}
                setCloneConfirmationVisible={setCloneConfirmationVisible}
                selectedClone={selectedClone}
                setSelectedClone={setSelectedClone}
              />
            }
          />
          <Panel
            title="Acknowledgements"
            help_code={'ACKNOWLEDGEMENTS'}
            panel_type={'achknowledgements'}
            body={<h1>not yet implemented</h1>}
          />
          <Panel
            title="Linkage Levels"
            help_code={'LINKAGELEVELS'}
            panel_type={'linkagelevels'}
            body={
              <Accordion>
                {
                  <LinkageLevelsSettings
                    updateLinkageLevelMedia={updateLinkageLevelMedia}
                    selectedLinkageLevels={selectedLinkageLevels}
                    updateSelectedLinkageLevels={updateSelectedLinkageLevels}
                    project={project}
                    userObj={userObj}
                    neighborhood={neighborhood}
                    setShortDesc={shortDesc}
                    setLongDesc={longDesc}
                    updateLinkageLevels={updateLinkageLevels}
                    lastHref={lastHref}
                  />
                }
              </Accordion>
            }
          ></Panel>
          <Panel
            title="Nodes"
            help_code={'NODES'}
            panel_type={'nodes'}
            body={<NeighborhoodNodesSettings setRefresh={setRefresh} />}
          />
          <Panel
            title="Media"
            help_code={'MEDIA'}
            panel_type={'media'}
            body={
              <NeighborhoodMediaGrid
                updateMedia={updateMedia}
                setRefresh={setRefresh}
                setEditedMedia={setEditedMedia}
              />
            }
          />
          <Panel
            title="Memberships"
            help_code={'MEMBERSHIPS'}
            panel_type={'memberships'}
            body={<h1>not yet implemented</h1>}
          />
          <Panel
            title="Styles"
            help_code={'STYLES'}
            panel_type={'styles'}
            body={<h1>not yet implemented</h1>}
          />
        </Accordion>
      </div>
    </>
  );
};

export default NeighborhoodForm;
