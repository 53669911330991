/**
 * @summary formatCanvasMapData.js
 * @file Extremely Important Util: Takes Our Data and Reformats it into a Data Obj that Can Be Used by GoJS
 * @returns {JSX}
 * @usedBy CanvasWrapper.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

const formatCanvasMapData = (
  userObj,
  project,
  branch,
  nodes,
  connections,
  neighborhoods,
  branchChanges
) => {
  if (!project) {
    const mapDataObj = {
      projectHeadline: 'No Project Selected',
      nodeDataArray: [],
      linkDataArray: [],
      skipsDiagramUpdate: false
    };
    return mapDataObj;
  } else {
    ///////////////////////////////////////////////////////////////
    // FORMATTING NEIGHBORHOODS AND PUSHING THEM INTO NODE ARRAY //
    ///////////////////////////////////////////////////////////////
    const formatNeighborhoods = (neighborhoodItem) => {
      if (neighborhoodItem != undefined) {
        let newNeighborhoodFormat = {
          ...neighborhoodItem,
          nodeKey: neighborhoodItem.id,
          category: "Super",
          type: "Neighborhood",
          visible: neighborhoodItem.visible || false,
        };
        if (neighborhoodItem.nodeIds) {
          newNeighborhoodFormat.nodes = neighborhoodItem.nodeIds.map((id) => {
            return nodes.find((node) => node.id === id);
          });
        }
        return newNeighborhoodFormat;
      }
    };
    const gojsNeighborhoods = neighborhoods?.map(formatNeighborhoods);
    nodes.push(...gojsNeighborhoods);
    ////////////////////////////////////////////////////////////////
    // FORMATTING NODES AND NEIGHBORHOODS TOGETHER INTO ONE ARRAY //
    ////////////////////////////////////////////////////////////////
    const formatNodesAndNeighborhoods = (nodeOrNeighborhood) => {
      if (nodeOrNeighborhood !== undefined) {
        if(nodeOrNeighborhood.type !== "Neighborhood"){
          // DEFAULT NODE THEMES
          const defaultNodeThemes = {
            shape: 'RoundedRectangle',
            color: '#FFFFFF',
            borderColor: '#1560b7',
            borderStyle: ['stroke', 'color'],
            borderThickness: 2,
            highlightColor: 'red',
            primaryFont: 'Bolder 16px Arial',
            secondaryFont: '100 14px Arial',
            fontColor: '#000000',
            corners: 'Rounded',
          }
          let newNodeFormat = {
            ...nodeOrNeighborhood,
            // DATA PROPERTIES
            key: nodeOrNeighborhood.id,
            visible: nodeOrNeighborhood.visible || true,
            title: nodeOrNeighborhood.name,
            name: nodeOrNeighborhood.name,
            connectedNodes: [],
            supers: nodeOrNeighborhood.supers || [],
            checkedOutBy: nodeOrNeighborhood.checkedOutBy ? nodeOrNeighborhood.checkedOutBy : 'Open',
            isCheckedOut: nodeOrNeighborhood.branchId === branch.id && nodeOrNeighborhood.checkedOutBy === userObj.screenName,
            added: branchChanges?.adds?.nodes?.find(editedNode => editedNode.id === nodeOrNeighborhood.id) ? true : false,
            edited: branchChanges?.edits?.nodes?.find(editedNode => editedNode.new.id === nodeOrNeighborhood.id) ? true : false,
            deleted: nodeOrNeighborhood?.deleted ? true : false,
            // THEME PROPERTIES
            shape: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.shape : defaultNodeThemes.shape,
            color: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.color : defaultNodeThemes.color,
            originalColor: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.originalColor : defaultNodeThemes.color,
            border: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.border : defaultNodeThemes.borderColor,
            strokeWidth: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.strokeWidth : defaultNodeThemes.borderThickness,
            fontPrimary: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.fontPrimary : defaultNodeThemes.primaryFont,
            fontSecondary: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.fontSecondary : defaultNodeThemes.secondaryFont,
            fontColor: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.fontColor : defaultNodeThemes.fontColor,
            originalFontColor: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.fontColor : defaultNodeThemes.fontColor,
          };

          gojsNeighborhoods.forEach(function (neighborhood) {
            if (neighborhood.nodes) {
              // Map Subordinate Nodes of Each Neighborhood
              neighborhood.nodes.forEach(function (neighborhoodNode) {
                // If a Node's Nodekey Matches a Neighborhood's SubNode's Nodekey...
                if (neighborhoodNode.nodeKey === nodeOrNeighborhood.nodeKey) {
                  // This will enable GoJS to see what nodes each neighborhood needs to wrap around.
                  newNodeFormat.supers.push(neighborhood.id);
                }
              });
            }
          });

          return newNodeFormat;
        } else {
          // DEFAULT NEIGHBORHOOD THEMES
          const defaultNeighborhoodThemes = {
            shape: 'RoundedRectangle',
            color: '#FAF03B',
            borderColor: '#1560b7',
            borderStyle: ['stroke', 'color'],
            borderThickness: 2,
            highlightColor: 'red',
            primaryFont: 'Bolder 24px Arial',
            secondaryFont: '100 14px Arial',
            fontColor: '#000000',
            corners: 'Rounded',
          }
          let newNeighborhoodFormat = {
            ...nodeOrNeighborhood,
            // DATA PROPERTIES
            key: nodeOrNeighborhood.id,
            visible: nodeOrNeighborhood.visible || false,
            title: nodeOrNeighborhood.name,
            name: nodeOrNeighborhood.name,
            connectedNodes: [],
            checkedOutBy: nodeOrNeighborhood.checkedOutBy ? nodeOrNeighborhood.checkedOutBy : 'Open',
            isCheckedOut: nodeOrNeighborhood.branchId === branch.id  && nodeOrNeighborhood.checkedOutBy === userObj.screenName,
            _members: [],
            nodeIds: [],
            // CRUD PROPERTIES
            added: branchChanges?.adds?.neighborhoods?.find(editedNode => editedNode.id === nodeOrNeighborhood.id) ? true : false,
            edited: branchChanges?.edits?.neighborhoods?.find(editedNode => editedNode.new.id === nodeOrNeighborhood.id) ? true : false,
            deleted: nodeOrNeighborhood?.deleted ? true : false,
            // THEME PROPERTIES
            shape: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.shape : defaultNeighborhoodThemes.shape,
            backgroundColor: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.color : defaultNeighborhoodThemes.color,
            originalColor: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.originalColor : defaultNeighborhoodThemes.color,
            border: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.border : defaultNeighborhoodThemes.borderColor,
            strokeWidth: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.strokeWidth : defaultNeighborhoodThemes.borderThickness,
            fontPrimary: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.fontPrimary : defaultNeighborhoodThemes.primaryFont,
            fontSecondary: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.fontSecondary : defaultNeighborhoodThemes.secondaryFont,
            fontColor: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.fontColor : defaultNeighborhoodThemes.fontColor,
            originalFontColor: nodeOrNeighborhood.theme ? nodeOrNeighborhood.theme.fontColor : defaultNeighborhoodThemes.fontColor,
          };
          if (nodeOrNeighborhood.linkageLevels?.length > 0) {
            for (let i = 0; i < nodeOrNeighborhood.linkageLevels.length; i++) {
              newNeighborhoodFormat._members.push(nodeOrNeighborhood.linkageLevels[i].id);
            }
          }
          if (nodeOrNeighborhood.nodes.length > 0) {
            nodeOrNeighborhood.nodes.forEach(function(node) {
              if(node?.nodeKey) {
                newNeighborhoodFormat._members.push(node.nodeKey);
                newNeighborhoodFormat.nodeIds.push(node.id)
              }
            });
          }
    
          newNeighborhoodFormat.supers = nodeOrNeighborhood.supers || [];
          gojsNeighborhoods.forEach(function (neighborhood) {
            if (neighborhood.nodes) {
              // Map Subordinate Nodes of Each Neighborhood
              neighborhood.nodes.forEach(function (neighborhoodNode) {
                // If a Node's Nodekey Matches a Neighborhood's SubNode's Nodekey...
                if (neighborhoodNode.nodeKey === nodeOrNeighborhood.nodeKey) {
                  // This will enable GoJS to see what nodes each neighborhood needs to wrap around.
                  newNeighborhoodFormat.supers.push(neighborhood.id);
                }
              });
            }
          });
          return newNeighborhoodFormat;
        }
      }
    }; 
    
    // Format Nodes into Function Above
    const gojsNodes = nodes.map(formatNodesAndNeighborhoods);
    ////////////////////////////
    // CONNECTIONS FORMATTING //
    ////////////////////////////
    const formatConnections = (connectionItem) => {
      if (connectionItem !== undefined) {
        // DEFAULT CONNECTION THEMES
        const defaultConnectionThemes = {
          color: '#000000',
          strokeWidth: 2,
          highlightColor: "red",
          arrowHeadStyle: "Standard",
        }
        const formatConnections = {
          ...connectionItem,
          type: "Connection",
          key: connectionItem.id,
          from: connectionItem.sourceNodeKey,
          to: connectionItem.destinationNodeKey,
          sourceNodeId: connectionItem.origin_node_id,
          destinationNodeId: connectionItem.destination_node_id,
          checkedOutBy: connectionItem.checkedOutBy ? connectionItem.checkedOutBy : 'Open',
          isCheckedOut: connectionItem.branchId === branch.id  && connectionItem.checkedOutBy === userObj.screenName,
          // CRUD PROPERTIES
          added: branchChanges?.adds?.connections?.find(newConnection => newConnection.id === connectionItem.id) ? true : false,
          edited: branchChanges?.edits?.connections?.find(newConnection => newConnection.new.id === connectionItem.id) ? true : false,
          deleted: connectionItem?.deleted ? true : false,
          // THEME PROPERTIES
          color: connectionItem?.theme?.color ? connectionItem.theme.color : defaultConnectionThemes.color,
          originalColor: connectionItem?.theme?.originalColor ? connectionItem.theme?.originalColor : connectionItem.theme?.color ? connectionItem.theme?.color : defaultConnectionThemes.color,
          strokeWidth: connectionItem?.theme?.strokeWidth ? connectionItem.theme.strokeWidth : defaultConnectionThemes.strokeWidth,
          originalStrokeWidth: connectionItem?.theme?.strokeWidth ? connectionItem.theme.strokeWidth : defaultConnectionThemes.strokeWidth,
          originalStrokeWidth: connectionItem?.theme?.originalStrokeWidth ? connectionItem.theme?.originalStrokeWidth : defaultConnectionThemes.strokeWidth,
          arrowHeadStyle: connectionItem?.theme?.arrowHeadStyle ? connectionItem.theme?.arrowHeadStyle : defaultConnectionThemes.arrowHeadStyle,
        };

        return formatConnections;
      }
    };

    const gojsConnections = connections.map(formatConnections);

    // FORMATTING DELETED ELEMENTS //
    let formattedBranchChanges = {
      adds:  branchChanges.adds,
      edits: branchChanges.edits,
      // deletes: branchChanges.deletes
      deletes: {
        nodes: branchChanges.deletes.nodes.map(formatNodesAndNeighborhoods),
        connections: branchChanges.deletes.connections.map(formatConnections),
        neighborhoods: branchChanges.deletes.neighborhoods.map(formatNeighborhoods).map(formatNodesAndNeighborhoods)
      }
    }

    ////////////////////////////////
    // FINAL FORMATTED MAP OBJECT //
    ////////////////////////////////
    const defaultModelData = {
      layoutDirection: 90,
      layeringOption: 'LayerLongestPathSource',
      arrowHeadStyle: 'Standard',
    };
    
    const mapDataObj = {
      projectHeadline: project.displayTitle,
      selectedBranch: branch ? branch.name : userObj.selectedBranch[0].name,
      nodeDataArray: gojsNodes,
      originalNodeDataArray: gojsNodes,
      linkDataArray: gojsConnections,
      originalLinkDataArray: gojsConnections,
      modelData: defaultModelData,
      branchChangeData: formattedBranchChanges,
      skipsDiagramUpdate: false
    };
    return mapDataObj;
  }
};

export default formatCanvasMapData;