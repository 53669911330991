/**
 * @summary InspectorTool.js
 * @file Modal for Containing Injuey Data for Selected Element(s). Opened through CanvasInterface.js After at least one Element is Selected
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../canvasPage.scss';
import Modal from 'react-bootstrap/Modal';
import CKEditor from 'shared/ckEditor/CKEditor';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';


const EditNeighborhoodCanvasModal = ({
    handleCrud,
    selectedNeighborhood,
    handleEditModalVisibility,
    renderMapData,
    handleTableviewNavigation
  }) => {
    // REDUX DATA
    const selectedNeighborhoodFound = renderMapData.nodeDataArray.filter(node => node.nodeKey === selectedNeighborhood.nodeKey)[0]
    const userObj = useSelector((state) => state.authReducer.userObj) 
    const reduxNodes = useSelector((state) => Object.values(state.nodeReducer));
    const reduxAttributes = useSelector((state) => state.projectSetupReducer.neighborhoodsSelectedValues);
    const reduxSubjects = reduxAttributes.find(setting => setting.name === "subject");
    const reduxFrameworks = reduxAttributes.find(setting => setting.name === "content_framework_type");
    
    // GET MEMBER NODE IDS FROM SELECTION
    const memberNodes = selectedNeighborhoodFound._members.map(memberNode => {
        let reduxMemberNode = renderMapData.nodeDataArray.find(node => node.nodeKey === memberNode)
        return reduxMemberNode
    })
    // MODAL SELECTIONS BY USER
    // User Changes these States Throughout the Modal
    // Attributes are handles in states below, and formatted into saveNeighborhoodAttributes through a useEffect
    const [saveNeighborhoodName, setSaveNeighborhoodName] = useState(selectedNeighborhoodFound.name)
    const [saveNeighborhoodDescription, setSaveNeighborhoodDescription] = useState(selectedNeighborhoodFound.description)
    const [saveNeighborhoodAttributes, setSaveNeighborhoodAttributes] = useState(null)

    // MEMBER NODE STATES
    const [selectedNeighborhoodKeys, setSelectedNodeKeys] = useState([])
    const [allNodeKeysAvailable, setAllNodeKeysAvailable] = useState([])

    // ATTRIBUTE STATES
    // These Set the Correct Available Attributes and Handle Selections
    // Attributes are formatted in a useEffect and set into "saveNeighborhoodAttributes" above
    const [availableSubjects, setAvailableSubjects] = useState(null)
    const [availableFrameworks, setAvailableFrameworks] = useState(null)
    const [selectedSubjects, setSelectedSubjects] = useState(null)
    const [selectedFrameworks, setSelectedFrameworks] = useState(null)
    const [originalSubjects, setOriginalSubjects] = useState(null)
    const [originalFrameworks, setOriginalFrameworks] = useState(null)
    const [disableSubjects, setDisableSubjects] = useState(false)
    const [disableFrameworks, setDisableFrameworks] = useState(false)

    // USE EFFECT FOR GRABBING MEMBER NODES
    // Grabs all Nodes checked out in this branch, and formats for an autopopulate selector below
    useEffect(() => {
        // IF BRANCH
        setAllNodeKeysAvailable([])
        if(memberNodes.length > 0){
            const formattedBranchNodes = memberNodes.map((node) => ({name: node.nodeKey, id: node.id}))
            setSelectedNodeKeys(formattedBranchNodes)
        }
        const branchNodes = reduxNodes.filter(node => node.branchId === userObj.selectedBranch[0].id)
        const formattedBranchNodes = branchNodes.map((node) => ({name: node.nodeKey, id: node.id}))
        setAllNodeKeysAvailable(formattedBranchNodes)
    }, [])

    // USER EFFECT FOR FORMATTING FINAL ATTRIBUTE OBJ
    // Whenever the User changes the subject or framework selection, 
    // THose states are formatted into this settings object to be placed in the json object that gets saved.
    useEffect(()=>{
        let settings = {
            details: [],
            metadata: [],
            taxonomies: [],
            flags: []
        }
        // Format Pre-Existing Attributes Into Project
        if(selectedNeighborhood.settings?.attributes){
            selectedNeighborhood.settings.attributes.forEach(attributeParent => {
                let reduxAttribute = reduxAttributes.find(reduxAttribute => reduxAttribute.name === Object.keys(attributeParent)[0])
                let formattedAttribute = {
                    attrId: reduxAttribute.id,
                    value: []
                }
                Object.values(attributeParent).forEach(attributes => {
                    attributes.forEach(attributeData2 => {
                        let attributeValues = {
                            ...attributeData2.selectedId && { id: attributeData2.selectedId},
                            value: attributeData2.value,
                            nodeValueId: attributeData2.nodeAttributeId,
                            nodeAttrId: attributeData2.nodeAttributeId,
                        }
                        formattedAttribute.value.push(attributeValues)
                    }) 
                })
                if(reduxAttribute.category === "Node Details"){
                    settings.details.push(formattedAttribute)   
                } else if(reduxAttribute.category === "Taxonomies and Dimensions"){
                    settings.taxonomies.push(formattedAttribute)
                } else if(reduxAttribute.category === "Accessibility Flags"){
                    settings.flags.push(formattedAttribute)
                } else if(reduxAttribute.category === "Content Metadata"){
                    settings.metadata.push(formattedAttribute)
                }    
            })
            const foundSubject = settings.metadata.find(metaAttributeValue => metaAttributeValue?.attrId === reduxSubjects?.id)
            const foundFramework = settings.metadata.find(metaAttributeValue => metaAttributeValue?.attrId === reduxFrameworks?.id)
            if(!foundSubject){
                settings.metadata.push({ attrId: reduxSubjects.id, value: [] })
            } 
            if(!foundFramework){
                settings.metadata.push({ attrId: reduxFrameworks.id, value: [] })
            }
            setSaveNeighborhoodAttributes(settings)
        } else {
            let defaultSettings = {
                details: [],
                metadata: [
                    {attrId: reduxSubjects?.id, value: []},
                    {attrId: reduxFrameworks?.id, value: []}
                ],
                taxonomies: [],
                flags: []
            }
            setSaveNeighborhoodAttributes(defaultSettings)
        }   
    },[])
    
    // USE EFFECT FOR FORMATTING AVAILABLE ATTRIBUTES
    // Filters Subjects and Frameworks from Redux Attributes, and Formats them for save-ability
    useEffect(() => {
        if(reduxAttributes.filter(setting => setting.name === "subject")){
            const subject = reduxAttributes.find(setting => setting.name === "subject");
            if(subject){
                let formattedSubjects = {
                    attrId: reduxSubjects.id,
                    value: []
                }
                subject.value.forEach(subjectValues => {
                    let formattedSubjectValues = {
                        id: subjectValues.id,
                        value: subjectValues.name
                    }
                    formattedSubjects.value.push(formattedSubjectValues)
                })
                setAvailableSubjects(formattedSubjects)
            }
        
        }
        if(reduxAttributes.filter(setting => setting.name === "content_framework_type")){
            const framework = reduxAttributes.filter(setting => setting.name === "content_framework_type")[0];
            if(framework){
                let formattedFrameworks = {
                    attrId: reduxFrameworks.id,
                    value: []
                }
                framework.value.forEach(frameworkValues => {
                    let formattedSubjectValues = {
                        id: frameworkValues.id,
                        value: frameworkValues.name
                    }
                    formattedFrameworks.value.push(formattedSubjectValues)
                })
                setAvailableFrameworks(formattedFrameworks)
            }
        }
    }, [])

    // USE EFFECT FOR GETTING SELECTED ATTRIBUTES
    // Filters Subjects and Frameworks from Redux Attributes, and Formats them for save-ability
    useEffect(() => {
        if(selectedNeighborhood.settings?.attributes){
            if(reduxSubjects !== undefined){
                let neighborhoodSubjects = selectedNeighborhood.settings.attributes.filter(attribute => {
                    if(Object.keys(attribute)[0] === "subject"){
                        return attribute
                    }
                })
                let formattedSubject = {
                    attrId: reduxSubjects.id,
                    value: []
                }
                if(neighborhoodSubjects[0]?.subject.length > 0){
                    neighborhoodSubjects[0].subject.forEach(subjectValues => {
                        formattedSubject.value.push({value: subjectValues.value, id: subjectValues.selectedId, nodeValueId: subjectValues.nodeAttributeId, nodeAttrId: subjectValues.nodeAttributeId})
                    })
                    setSelectedSubjects(formattedSubject)
                    setOriginalSubjects(formattedSubject)
                } else {
                    setSelectedSubjects(formattedSubject)
                    setOriginalSubjects(formattedSubject)
                }
            } else {
                if(reduxSubjects === undefined){
                    setDisableSubjects(true)
                }
            }
            if(reduxFrameworks !== undefined){
                let neighborhoodFrameworks = selectedNeighborhood.settings.attributes.filter(attribute => {
                    if(Object.keys(attribute)[0] === "content_framework_type"){
                        return attribute
                    }
                })
                let formattedFramework = {
                    attrId: reduxFrameworks.id,
                    value: []
                }
                if(neighborhoodFrameworks[0]?.content_framework_type.length > 0){
                    neighborhoodFrameworks[0].content_framework_type.forEach(frameworkValues => {
                        formattedFramework.value.push({value: frameworkValues.value, id: frameworkValues.selectedId, nodeValueId: frameworkValues.nodeAttributeId, nodeAttrId: frameworkValues.nodeAttributeId})
                    })
                    setOriginalFrameworks(formattedFramework)
                    setSelectedFrameworks(formattedFramework)
                } else {
                    setOriginalFrameworks(formattedFramework)
                    setSelectedFrameworks(formattedFramework)
                }
            } else {
                if(reduxFrameworks === undefined){
                    setDisableFrameworks(true)
                }
            }
        } else {
            if(reduxSubjects !== undefined){
                let formattedSubject = {
                    attrId: reduxSubjects.id,
                    value: []
                }
                setSelectedSubjects(formattedSubject)
                setOriginalSubjects(formattedSubject)
            } else {
                setDisableSubjects(true)
            }
            if(reduxFrameworks !== undefined){
                let formattedFramework = {
                    attrId: reduxFrameworks.id,
                    value: []
                }
                setOriginalFrameworks(formattedFramework)
                setSelectedFrameworks(formattedFramework)
            } else {
                setDisableFrameworks(true)
            }    

        }
    },[])

    // USE EFFECT FOR FORMATTING PRE SELECTED ATTRIBUTES
    // Filters Subjects and Frameworks from Redux Attributes, and Formats them for save-ability
    // useEffect(()=>{
    //     if(selectedSubjects){
    //         let metadata = []
    //         saveNeighborhoodAttributes.metadata.forEach(metaAttribute => {
    //             if(selectedSubjects.attrId === metaAttribute.attrId){
    //                 metadata.push(selectedSubjects)
    //             } else if(selectedFrameworks.attrId === metaAttribute.attrId){
    //                 metadata.push(selectedFrameworks)
    //             } else if(metaAttribute.attrId !== selectedSubjects.attrId && metaAttribute.attrId !== selectedFrameworks.attrId) {
    //                 metadata.push(metaAttribute)
    //             }
    //         })
    //         let formattedMetadata = {
    //             details: saveNeighborhoodAttributes.details,
    //             metadata: metadata,
    //             taxonomies: saveNeighborhoodAttributes.details,
    //             flags: saveNeighborhoodAttributes.details,
    //         }
    //         setSaveNeighborhoodAttributes(formattedMetadata)
    //     }
    // },[selectedSubjects, selectedFrameworks])

    
    //////////////////
    //  SAVE OBJECT //
    //////////////////
    const jsonNeighborhood = {
        action: 'edit',
        elementType: 'neighborhood',
        id: selectedNeighborhoodFound.id,
        projectId: userObj.selectedProject[0].id,
        branchId: selectedNeighborhoodFound.branchId,
        name: saveNeighborhoodName,
        description: saveNeighborhoodDescription,
        nodes: selectedNeighborhoodKeys.map(node=> node.id),
        settings: saveNeighborhoodAttributes
    }
    ////////////////////
    // EVENT HANDLERS //
    ////////////////////
    const handleSaveNewNeighborhood = (e) => {
        if(selectedNeighborhood.isCheckedOut){
            if(saveNeighborhoodName){
                handleCrud(jsonNeighborhood)         
                handleEditModalVisibility()
            } else {
                if(saveNeighborhoodName === null){
                    return toast.error("Enter a Neighborhood Name")
                }
            }
        } else {
            return toast.error("This neighborhood is not checked out.")
        }
    }

    const handleNeighborhoodNameInputChange = (e) => {
        setSaveNeighborhoodName(e.target.value)
    }

    const handleNeighborhoodDescriptionInputChange = (fieldName, htmlName) => {
        setSaveNeighborhoodDescription(htmlName)
    }

    // FORMAT SUBJECT INPUTS
    const handleSubjectInputChange = (attributeInputs) => {
        if(attributeInputs.length > 0){
            let formattedAttribute = {
                attrId: selectedSubjects.attrId,
                value: attributeInputs
            }
            setSelectedSubjects(formattedAttribute)
        } else {
            if(originalSubjects?.value?.length === 0){
                let emptyAttribute = {
                    attrId: reduxSubjects.id,
                    value: []
                }
                setSelectedSubjects(emptyAttribute)
            } else {
                toast.error("You cannot completely clear Subjects once one has been saved. You must select at least one.")
            }
        }
    }

    // FORMAT FRAMWORK INPUTS
    const handleFrameworkInputChange = (attributeInputs) => {
        if(attributeInputs.length > 0){
            let formattedAttribute = {
                attrId: selectedFrameworks.attrId,
                value: attributeInputs
            }
            setSelectedFrameworks(formattedAttribute)
        } else {
            if(originalFrameworks?.value?.length === 0){
                let emptyAttribute = {
                    attrId: reduxFrameworks.id,
                    value: []
                }
                setSelectedFrameworks(emptyAttribute)
            } else {
                toast.error("You cannot completely clear Frameworks  once one has been saved. You must select at least one.")
            }
        }
    }

    // USE EFFECT FOR FORMATTING SELECTIONS INTO METADATA
    // Filters Subjects and Frameworks from Redux Attributes, and Formats them for save-ability
    useEffect(()=>{
        if(saveNeighborhoodAttributes && selectedSubjects && selectedFrameworks){
            let metadata = [...saveNeighborhoodAttributes.metadata]
            let newMetaData = metadata.map(metaAttribute => {
                if(selectedSubjects.attrId === metaAttribute.attrId){
                    return { attrId: selectedSubjects.attrId, value: selectedSubjects.value }
                } else if(selectedFrameworks.attrId === metaAttribute.attrId){
                    return { attrId: selectedFrameworks.attrId, value: selectedFrameworks.value }
                } else {
                    return metaAttribute
                }
            })
            let formattedMetadata = {
                details: saveNeighborhoodAttributes.details,
                metadata: newMetaData,
                taxonomies: saveNeighborhoodAttributes.details,
                flags: saveNeighborhoodAttributes.details,
            }
            setSaveNeighborhoodAttributes(formattedMetadata)
        }
    },[selectedSubjects, selectedFrameworks])

    // FORMAT ATTRIBUTES FOR MULTISELECT - They're a little complex to unpack
    let subjectDropdownOptions = availableSubjects ? availableSubjects.value : []
    let subjectInputs = selectedSubjects ? selectedSubjects.value : []
    let frameworkDropdownOptions = availableFrameworks ? availableFrameworks.value : []
    let frameworkInputs = selectedFrameworks ? selectedFrameworks.value : []
    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <div className='modal-header-text'>
                        Edit Neighborhood
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-12'>
                        <div className='instructional-blue pt-3 pb-3'>
                            <div className='col-12'>
                                Edit the fields you would like changed, and click Save. Redirect to Tableview for more configuration options on the tableview page.
                            </div>
                            
                        </div>
                        <div className='ps-3 pb-4'>
                            <div className='row mt-1'>
                                
                                <div className='col-9'>
                                    <div className='ms-3 pt-3 bold-instruction'>
                                            Advanced Settings will open a new tab where you can 
                                            customize your node with more in-depth options.
                                    </div>
                                </div>
                                <div className="col-3 pe-5">   
                                    <button onClick={handleTableviewNavigation} className="btn btn-success w-100 w-75 h-75">Redirect to Tableview</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container pb-4 px-4'>
                    <div className='col-12'>
                        <div className="data-editor-section">
                            <div className="col-12 data-editor-subheader">
                                Neighborhood Name
                            </div>
                            <div className="col-12">
                                    <input 
                                        placeholder="Enter a Node Key" 
                                        value={saveNeighborhoodName}
                                        onChange={handleNeighborhoodNameInputChange}
                                        type="text">
                                    </input>            
                            </div>
                        </div>
                        <div className="data-editor-section">
                            <div className="col-12 data-editor-subheader">
                                Neighborhood Description
                            </div>
                            <div className="col-12">
                                <CKEditor
                                    data={saveNeighborhoodDescription}
                                    fieldName="description"
                                    maxWordCount={1000}
                                    onChange={handleNeighborhoodDescriptionInputChange}
                                />
                            </div>
                        </div>
                        <div className="data-editor-section">
                            <div className='"col-12 data-editor-subheader"'>
                                Neighborhood Member Nodes
                            </div>
                            <div className="col-12">
                                <MultiSelect
                                    data={allNodeKeysAvailable}
                                    style={{
                                        paddingRight: "130px",
                                        color: "black"
                                        // maxHeight: "37px"
                                    }}
                                    dataItemKey='id'
                                    textField='name'
                                    value={selectedNeighborhoodKeys}
                                    placeholder={!disableSubjects ? 'Enter the Subjects You Would Like to Add.' : 'No Subjects Available. Check Node Settings in Project Settings.'}
                                    onChange={(e) => {
                                        setSelectedNodeKeys(e.target.value)
                                    }}
                                >
                                </MultiSelect>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="data-editor-section col-12">
                                    <div className='col-2 d-inline data-editor-subheader pe-1'>
                                        Subject: 
                                    </div>
                                    <MultiSelect
                                        disabled={disableSubjects}
                                        data={subjectDropdownOptions}
                                        style={{color: "black"}}
                                        dataItemKey='id'
                                        textField='value'
                                        value={subjectInputs}
                                        placeholder={!disableFrameworks ? 'Enter the Frameworks You Would Like to Add.' : 'No Frameworks Available. Check Node Settings in Project Settings.'}
                                        onChange={(e) => {
                                            handleSubjectInputChange(e.target.value)
                                        }}
                                    >
                                    </MultiSelect>
                                    
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="data-editor-section col-12">
                                    <div className='col-2 d-inline  data-editor-subheader pe-1'>
                                        Content Framework:
                                    </div>
                                    <MultiSelect
                                        disabled={disableFrameworks}
                                        data={frameworkDropdownOptions}
                                        style={{color: "black"}}
                                        dataItemKey='id'
                                        textField='value'
                                        value={frameworkInputs}
                                        placeholder={'Enter the Frameworks You Would Like to Add.'}
                                        onChange={(e) => {
                                            handleFrameworkInputChange(e.target.value)
                                        }}
                                    >
                                    </MultiSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <button 
                                className="btn btn-primary btn-sm text-white"
                                onClick={handleSaveNewNeighborhood}
                            >
                                Save Edited Node
                            </button>
                        </div>
                        <div className='col-6'>
                            <button 
                                className="btn btn-danger btn-sm text-white" 
                                onClick={handleEditModalVisibility}
                            >
                                Close
                            </button>
                        </div>             
                    </div>
                </div>
            </Modal.Footer>
        </>
    );
} 

EditNeighborhoodCanvasModal.propTypes = {
  handleCrud: PropTypes.func,
  selectedNeighborhood: PropTypes.array,
  handleEditModalVisibility: PropTypes.func,
  renderMapData: PropTypes.array,
  handleTableviewNavigation: PropTypes.func
};

export default EditNeighborhoodCanvasModal;