/**
 * @summary InspectorTool.js
 * @file Modal for Containing Injuey Data for Selected Element(s). Opened through CanvasInterface.js After at least one Element is Selected
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import axios from 'axios';
import { LuGlasses } from 'react-icons/lu';

import AddModal from './crudModals/AddNeighborhoodCanvasModal';
import EditModal from './crudModals/EditNeighborhoodCanvasModal';
import DeleteModal from './crudModals/DeleteNeighborhoodCanvasModal'

import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';

const NeighborhoodMiniGrid = ({
    handleCrud,
    branchChangeData,
    canvasNeighborhoodSelections,
    tableNeighborhoodSelections,
    setCanvasNeighborhoodSelections,
    renderMapData,
    selectedNodesIncluded,
    tableNodeSelections,
    handleTableviewNavigation,
    onPublishedBranch
  }) => {

    const DATA_ITEM_KEY = "nodeKey";
    const SELECTED_FIELD = "selected";

    const [addModalVisible, setAddModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)

    const handleAddModalVisibility = () => {
        setAddModalVisible(!addModalVisible)
    }

    const handleEditModalVisibility = () => {
        setEditModalVisible(!editModalVisible)
    }

    const handleDeleteModalVisibility = () => {
        setDeleteModalVisible(!deleteModalVisible)
    }

    useEffect(() => {
        if(selectedNodesIncluded){
            setAddModalVisible(true)
        }
    },[selectedNodesIncluded])

    const handleDataEditorSelections = (e) => {
        if(e.dataItem?.nodeKey && e.dataItem.category === "Super"){        
            let newCanvasNeighborhoodSelections = canvasNeighborhoodSelections.map(neighborhood => {
                if(neighborhood.nodeKey === e.dataItem.nodeKey){
                    neighborhood.selected = !neighborhood.selected
                }
                return neighborhood
            })
            setCanvasNeighborhoodSelections(newCanvasNeighborhoodSelections)
        }
    };

    const handleSelectAll = () => {
        if(canvasNeighborhoodSelections.some(neighborhood => !neighborhood.selected)){
            let newCanvasNeighborhoodSelections = canvasNeighborhoodSelections.map(neighborhood => {
                neighborhood.selected = true
                return neighborhood
            })
            setCanvasNeighborhoodSelections(newCanvasNeighborhoodSelections)
        } else {
            let newCanvasNodeSelections = canvasNeighborhoodSelections.map(neighborhood => {
                neighborhood.selected = false
                return neighborhood
            })
            setCanvasNeighborhoodSelections(newCanvasNodeSelections)
        }
    }
    // FORMAT CHANGE LOG MESSAGING
    canvasNeighborhoodSelections.map(neighborhood => {
        if(neighborhood.deleted){
            neighborhood.checkedOutMessage = neighborhood.isCheckedOut ? "Yes" : "No"
            neighborhood.changeMessage = "Deleted"
            return neighborhood
        } else if(neighborhood.added){
            neighborhood.checkedOutMessage = neighborhood.isCheckedOut ? "Yes" : "No"
            neighborhood.changeMessage = "Added"
            return neighborhood
        } else if(neighborhood.edited){
            neighborhood.checkedOutMessage = neighborhood.isCheckedOut ? "Yes" : "No"
            let neighborhoodChange = branchChangeData.edits.neighborhoods.find(neighborhoodChange => neighborhoodChange.new.id === neighborhood.id)
            neighborhood.changeMessage = neighborhoodChange.message + " Updated"
            return neighborhood
        } else {
            neighborhood.checkedOutMessage = neighborhood.isCheckedOut ? "Yes" : "No"
            neighborhood.changeMessage = "None"
            return neighborhood
        }
    })
    return (
        <>
        <div>
            <Grid
                style={{
                    height: "300px",
                }}
                data={canvasNeighborhoodSelections}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                onSelectionChange={handleDataEditorSelections}
                onRowClick={handleDataEditorSelections}
                onHeaderSelectionChange={handleSelectAll}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                }}
            >
                <GridNoRecords>
                    Select Elements on Canvas to Display Here
                </GridNoRecords>
                <Column field={SELECTED_FIELD} width="50px" id="select-all"/>
                <Column field="nodeKey" title="ID" />
                <Column field="title" title="Name" width="250px" />
                <Column field="changeMessage" title="Branch Changes" />
                {/* <Column field="lastUpdated" title="Last Change" /> */}
                <Column field="checkedOutMessage" title="Checked Out" />
            </Grid>
        </div>
        <div className="container-fluid p-0 d-flex justify-content-between mt-2">
            <div className='col-12'>
                <div className='row'>
                    <div className='col-3'>
                        <button
                        className="btn btn-primary btn-sm text-white w-100 me-2 ms-4"
                        type="button"
                        onClick={handleAddModalVisibility}
                        title={onPublishedBranch ? "Creating Elements is disabled while on the published branch." : "Create a new Neighborhood."}
                        disabled={onPublishedBranch}
                        >
                        <i className="bi bi-plus me-2" />
                            Add Neighborhood
                        </button>
                    </div>
                    <div className='col-2'>
                        <button
                            className="btn btn-primary btn-sm w-100 ms-4"
                            type="button"
                            onClick={handleEditModalVisibility}
                            title={onPublishedBranch ? "Editing is disabled while on the published branch." : "Edit a selected Neighborhood."}
                            disabled={onPublishedBranch || tableNeighborhoodSelections.length !== 1}
                        >
                            <i className="bi bi-pencil me-2" />
                            Edit
                        </button>
                    </div>
                    <div className='col-2 text-right'>
                        <button
                        className="btn btn-danger btn-sm text-white w-100 ms-4"
                        type="button"
                        onClick={handleDeleteModalVisibility}
                        title={onPublishedBranch ? "Deleting is disabled while on the published branch." : "Delete Selected Nodes"}
                        disabled={onPublishedBranch || tableNeighborhoodSelections.length < 1}
                        >
                        <i className="bi bi-trash me-2" />
                            Delete
                        </button>
                    </div>
                    <div className='col-5'></div>
                </div>
            </div>
        </div>
        <Modal show={addModalVisible} backdrop="static" centered size="xl">
            <AddModal 
                handleCrud={handleCrud}
                selectedNodesIncluded={selectedNodesIncluded}
                handleAddModalVisibility={handleAddModalVisibility}
                handleTableviewNavigation={handleTableviewNavigation}
                tableNodeSelections={tableNodeSelections}
            />
        </Modal>
        <Modal show={editModalVisible} backdrop="static" centered size="xl">
            <EditModal
                handleCrud={handleCrud}
                selectedNeighborhood={tableNeighborhoodSelections[0]}
                handleEditModalVisibility={handleEditModalVisibility}
                renderMapData={renderMapData}
                handleTableviewNavigation={handleTableviewNavigation}
            />
        </Modal>
        <Modal show={deleteModalVisible} backdrop="static" centered >
            <DeleteModal
                selectedNeighborhoods={tableNeighborhoodSelections}
                handleCrud={handleCrud}
                handleDeleteModalVisibility={handleDeleteModalVisibility}
                renderMapData={renderMapData}
            />
        </Modal>
        </>
    );
  } 

NeighborhoodMiniGrid.propTypes = {
  canvasNeighborhoodSelections: PropTypes.array,
  tableNeighborhoodSelections: PropTypes.array,
  setCanvasNeighborhoodSelections: PropTypes.func,
  renderMapData: PropTypes.obj,
  handleCrud: PropTypes.func,
  branchChangeData: PropTypes.obj,
  tableNodeSelections: PropTypes.array,
  handleTableviewNavigation: PropTypes.func,
  selectedNodesIncluded: PropTypes.bool,
  onPublishedBranch: PropTypes.bool
};

export default NeighborhoodMiniGrid;