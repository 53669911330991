/**
 * @summary CanvasPage.js
 * @file Main Motherboard for the LM Canvas
 * @returns {JSX}
 * @usedBy CanvasWrapper.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 8/8/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { getViews } from 'store/views/ViewActions';
import './canvasPage.scss';
import { useImmer } from 'use-immer';
import { resetSelectedViewDataAction } from 'store/views/ViewActions';
import cloneDeep from 'lodash/cloneDeep';
import Canvas from './Canvas';
import inspectorUtils from './utils/inspectorUtils';
import filterViewData from './utils/filterViewData';
import CanvasFilter from './submenus/CanvasFilter';
import GlobalSidebar from './submenus/filterSubmenus/GlobalSidebar';
import CanvasDataEditor from './submenus/CanvasDataEditor'
import CanvasThemes from './submenus/CanvasThemeMenu';
import CanvasInspectorTool from './submenus/CanvasInspectorTool';
import CanvasTableviewRedirect from './submenus/CanvasTableviewRedirect';
import CanvasNeighborhoodLegend from './submenus/CanvasNeighborhoodLegend';
import CanvasInterface from './submenus/CanvasInterface';
import CanvasSaveView from './submenus/CanvasSaveView';
import CanvasLoadView from './submenus/CanvasLoadView';
import CanvasExportMap from './submenus/CanvasExortMap';
import formatDataForTableview from './utils/formatDataForTableview'
import CanvasTutorial from './submenus/CanvasTutorial'
import crudFormatter from './utils/crudFormatter'
import PropTypes from 'prop-types';
import { toggleFilterTableView } from 'store/auth/AuthActions';
import { useNavigate } from 'react-router-dom';
import { gridSelectedDataActions as addConnectionToSelection } from 'store/connections/ConnectionActions';
import { gridSelectedDataActions as addNodeToSelection } from 'store/nodes/NodeActions';
import { gridSelectedDataActions as addNeighborhoodToSelection } from 'store/neighborhoods/NeighborhoodActions';

import {
  addDataToCanvasSelection,
  resetCanvasSelection,
  setCanvasSelections
} from 'store/canvas/CanvasActions';
import store from 'store/store';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CanvasPage = ({ canvasMapData, handleRefresh }) => {
  const navigate = useNavigate();

  const views = useSelector((state) => {
    const views = state.viewReducer;

    // Filter selectedData instead of delete and then return activeViews
    const filteredViews = Object.fromEntries(
      Object.entries(views).filter(([key, value]) => key !== 'selectedData')
    );
    const activeViews = Object.values(filteredViews).filter(view => view.isActive);
    return activeViews;
  }, shallowEqual);
  
  const tableViewData = useSelector((state) => state.canvasReducer)

  //////////////////////////////
  // FORMATTING DATA FOR GOJS //
  //////////////////////////////
  // DIAGRAM DATA
  const [diagramData, updateDiagramData] = useImmer(canvasMapData.data);
  // RENDER STATE
  const [renderMapData, setRenderMapData] = useState();
  // DIAGRAM DATA ACCESS
  const [diagramAccess, setDiagramAccess] = useState(null);
  // REVIEW MODE DATA
  const [reviewModeOpen, setReviewModeOpen] = useState(false);
  const [editedElement, setEditedElement] = useState(null);

  // NEIGHNORHOOD STATES
  const [neighborhoodLegendArray, setNeighborhoodLegendArray] = useState([]);

  // FILTER STATES
  const [filtered, setFilter] = useState(false);
  const [filteredMapData, setFilteredMapData] = useState(null);

  // GLOBAL FILTER STATES
  const [globalKeywordInput, setGlobalKeywordInput] = useState(null);
  const [invisibleNodeArray, setInvisibleNodeArray] = useState([]);
  const [visibleNeighborhoodArray, setVisibleNeighborhoodArray] = useState([]);
  const [highlightElementsArray, setHighlightElementsArray] = useState([]);
  const [highlightLinksArray, setHighlightLinksArray] = useState([]);
  const [openElementsArray, setOpenElementsArray] = useState([]);

  // INSPECTION STATE
  const [isNodeSelected, setIsNodeSelected] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectionResources, setSelectionResources] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [multiSelectActive, setMultiSelectActive] = useState(false);
  const [multiSelectArray, setMultiSelectArray] = useState([]);

  // VIEW SAVE AND LOAD STATES
  const [selectedView, setSelectedView] = useState(null);

  // MENU VISIBILITY
  const [showDataEditorMenu, setShowDataEditorMenu] = useState(false)
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showThemeMenu, setShowThemeMenu] = useState(false);
  const [showInspectorMenu, setShowInspectorMenu] = useState(false);
  const [showTableviewRedirectMenu, setShowTableviewRedirectMenu] = useState(false);
  const [showCanvasTutorial, setShowCanvasTutorial] = useState(false)
  const [showNeighborhoodLegendMenu, setShowNeighborhoodLegendMenu] = useState(false);
  const [showViewSaveMenu, setShowViewSaveMenu] = useState(false);
  const [showViewLoadMenu, setShowViewLoadMenu] = useState(false);

  // Overlay Interfaces
  const [openGlobalSidebar, setOpenGlobalSidebar] = useState(false);

  useEffect(() => {
    if(canvasMapData.data.nodeDataArray.length === 0){
      setShowCanvasTutorial(true)
    } else {
      setShowCanvasTutorial(false)
    }
  },[canvasMapData])

  const loadCurrentViews = () => {
    store.dispatch(
      getViews(
        canvasMapData.selectedProject.id,
        canvasMapData.selectedBranch.id
      )
    );
  };

  useEffect(() => {
    if (showViewLoadMenu) {
      loadCurrentViews();
    }
  }, [showViewLoadMenu]);

  // DOWNLOAD IMAGES
  const [showMapDownloadModal, setShowMapDownloadModal] = useState(false);

  function handleCanvasExportDisplay() {
    setShowMapDownloadModal(true);
  }

  useEffect(() => {
    if (isNodeSelected === false) {
      setShowInspectorMenu(false);
    }
  }, [isNodeSelected]);

  // INITIAL LOAD
  useEffect(() => {
    store.dispatch(resetSelectedViewDataAction());
  }, []);

  // BUILDS NEIGHBORHOOD LEGEND //
  // Takes All Nodes from RenderMapData and Creates Array of Neighborhoods Using Nodes with Category: Super
  useEffect(() => {
    if (renderMapData) {
      let updatedLegendOptions = [];
      let clonedMap = cloneDeep(renderMapData);
      clonedMap.nodeDataArray?.forEach((neighborhood) => {
        if (neighborhood.category === 'Super') {
          updatedLegendOptions.push(neighborhood);
        }
      });
      updatedLegendOptions.map((neighborhood) => {
        if (visibleNeighborhoodArray.includes(neighborhood.name)) {
          neighborhood.visible = true;
          return neighborhood;
        }
      });
      setNeighborhoodLegendArray(updatedLegendOptions);
    }
  }, [renderMapData, diagramData]);

  ////////////////////////////
  // GOJS MODEL RESFRESHERS //
  ////////////////////////////
  const mapNodeKeyIdx = new Map();
  const mapLinkKeyIdx = new Map();

  // USE EFFECT HANDLES RENDERMAP FILTER CHANGES AND NEIGHBORHOOD LEGEND
  useEffect(() => {
    if (!filtered) {
      if (tableViewData?.canvasFormatted?.nodeDataArray) {
        let selectedNeighborhoods = tableViewData.canvasFormatted.nodeDataArray.filter((element) => element.category === 'Super');
        let neighborhoodNodeKeys = [];
        selectedNeighborhoods.forEach((neighborhood) => {
          neighborhoodNodeKeys.push(neighborhood.name);
        });
        setVisibleNeighborhoodArray(neighborhoodNodeKeys);

        tableViewData.canvasFormatted.nodeDataArray.map((node) => {
          node.visible = true;
          return node;
        });
        setRenderMapData(tableViewData.canvasFormatted);
      } else {
        setRenderMapData(diagramData);
      }
    } else {
      setRenderMapData(filteredMapData);
    }
  }, [canvasMapData, diagramData, filtered, filteredMapData]);

  useEffect(() => {
    if (filteredMapData?.filterType === 'Global Filter') {
      setOpenGlobalSidebar(true);
    }
  }, [renderMapData]);

  useEffect(() => {
    if (selectedNode !== null) {
      // Multi Select Handler
      if (multiSelectActive === true) {
        setMultiSelectArray([...multiSelectArray, selectedNode]);
      } else {
        setMultiSelectArray([selectedNode]);
      }
      // Node Selection Handler
      if (selectedNode.data) {
        if(selectedNode.deleted === false){
          inspectorUtils(selectedNode.data, canvasMapData).then(
            (axiosSelection) => {
              if (axiosSelection) {
                if (axiosSelection.nodeMedia) {
                  setSelectionResources(axiosSelection.nodeMedia);
                } else if (axiosSelection.neighborhoodMedia) {
                  setSelectionResources(axiosSelection.neighborhoodMedia);
                }
              }
            }
          );
        } else {
          setSelectionResources([])
        }
      }
    } else {
      setMultiSelectArray([]);
    }
  }, [selectedNode]);

  useEffect(() => {
    if (selectedConnection !== null) {
      if (multiSelectActive === true) {
        setMultiSelectArray([...multiSelectArray, selectedConnection]);
      } else {
        setMultiSelectArray([selectedConnection]);
      }
    } else {
      setMultiSelectArray([]);
    }
  }, [selectedConnection]);

  // REFRESHES DIAGRAM WITH NODE FILTER/LEGEND
  useEffect(() => {
    if (renderMapData) {
      refreshNodeIndex(renderMapData.nodeDataArray);
      refreshLinkIndex(renderMapData.linkDataArray);
    }
  }, [renderMapData]);

  function refreshNodeIndex(nodeArr) {
    mapNodeKeyIdx.clear();
    if (nodeArr !== undefined) {
      nodeArr.forEach((n, idx) => {
        mapNodeKeyIdx.set(n.key, idx);
      });
    }
  }
  function refreshLinkIndex(linkArr) {
    mapLinkKeyIdx.clear();
    if (linkArr !== undefined) {
      linkArr.forEach((l, idx) => {
        mapLinkKeyIdx.set(l.key, idx);
      });
    }
  }
  ///////////////////////////
  // MODEL CHANGE HANDLING //
  ///////////////////////////
  // TODO: Add Model Handlers for Interactive Things
  function handleModelChange() {
    // const insertedNodeKeys = obj.insertedNodeKeys;
    // const modifiedNodeData = obj.modifiedNodeData;
    // const removedNodeKeys = obj.removedNodeKeys;
    // const insertedLinkKeys = obj.insertedLinkKeys;
    // const modifiedLinkData = obj.modifiedLinkData;
    // const removedLinkKeys = obj.removedLinkKeys;
    // let modifiedModelData = obj.themeDataObj;
    // modifiedModelData.layoutDirection = 180;
    // updateDiagramData(modifiedModelData)
    // modifiedModelData.layoutDirection = 90;
    // TODO update state with above model changes
    // https://github.com/NorthwoodsSoftware/gojs-react-samples/blob/main/gojs-react-redux-basic/src/components/GoJSWrapper.tsx#L143
  }
  
  //////////////////////////////
  // MENU VISIBILITY CONTROLS //
  //////////////////////////////
  

  // Fites when Data Editor is opened
  function handleShowDataEditorMenu() {
    setShowThemeMenu(false);
    setShowFilterMenu(false);
    setShowInspectorMenu(false);
    setShowTableviewRedirectMenu(false);
    setShowDataEditorMenu(!showDataEditorMenu)
  }
  // Fires when Filter Menu is opened
  function handleFilterDisplay(e) {
    setShowDataEditorMenu(false)
    setShowThemeMenu(false);
    setShowInspectorMenu(false);
    setShowTableviewRedirectMenu(false);
    // setOpenGlobalSidebar(false)
    if (e === true) {
      setShowFilterMenu(true);
    } else {
      setShowFilterMenu(!showFilterMenu);
    }
  }
  // Fires when Theme Menu is opened
  function handleThemeMenuDisplay() {
    setShowDataEditorMenu(false)
    setShowInspectorMenu(false);
    setShowFilterMenu(false);
    setShowTableviewRedirectMenu(false);
    setShowThemeMenu(!showThemeMenu);
  }

  // INSPECTION FUNCTIONS - Displays the Inspector Submenu
  function handleInspectionToolDisplay() {
    setShowDataEditorMenu(false)
    setShowFilterMenu(false);
    setShowThemeMenu(false);
    setShowTableviewRedirectMenu(false);
    setShowInspectorMenu(!showInspectorMenu);
  }

  // TABLEVIEW REDIRECT MODAL HANDLER - Displays the Tableview Redirect Submenu
  function handleTableviewRedirectDisplay() {
    setShowDataEditorMenu(false)
    setShowFilterMenu(false);
    setShowThemeMenu(false);
    setShowInspectorMenu(false);
    setShowTableviewRedirectMenu(!showTableviewRedirectMenu);
  }

  // TABLEVIEW REDIRECT MODAL HANDLER - Displays the Tableview Redirect Submenu
  function handleCanvasTutorialVisibility() {
    setShowDataEditorMenu(false)
    setShowFilterMenu(false);
    setShowThemeMenu(false);
    setShowInspectorMenu(false);
    setShowCanvasTutorial(!showCanvasTutorial);
  }

  ///////////////////////////
  // DATA EDITOR FUNCTIONS //
  ///////////////////////////
  function handleCrud(element) {
    crudFormatter(canvasMapData, element, setEditedElement, setShowDataEditorMenu)
  }
  useEffect(() => {
    if(editedElement !== null){
      handleRefresh(true)
    }
  },[editedElement])

  //////////////////////
  // FILTER FUNCTIONS //
  //////////////////////
  // Fires when Filter Inputs are Submitted
  function handleFilteredMapData(filterData) {
    setShowInspectorMenu(false);
    setRenderMapData(null);
    setFilteredMapData(filterData);
    setOpenGlobalSidebar(true);
    setMultiSelectArray([])
    setMultiSelectActive(false)
    setReviewModeOpen(false)
    if (filterData?.filterType !== 'Neighborhood Filter') {
      setInvisibleNodeArray([]);
      setVisibleNeighborhoodArray([]);
      setOpenElementsArray([]);
    } else {
      setInvisibleNodeArray([]);
      setVisibleNeighborhoodArray([filterData.selection]);
      setOpenElementsArray([]);
    }
  }

  function handleGlobalSidebarDisplay() {
    setOpenGlobalSidebar(!openGlobalSidebar);
  }

  // When A Neighborhood's Visibility is Changed Via Global Filter,Handle It Through Neighborhood Legend
  useEffect(() => {
    if (renderMapData) {
      handleNeighborhoodSelection(visibleNeighborhoodArray);
    }
  }, [visibleNeighborhoodArray]);

  // Global Filter Highlighter Handler
  useEffect(() => {
    if (renderMapData) {
      let clonedMap = cloneDeep(renderMapData);
      clonedMap.nodeDataArray.map((node) => {
        if (node.category !== 'Super') {
          let selection = highlightElementsArray.find(
            (selectedNode) => selectedNode === node.nodeKey
          );
          if (selection) {
            node.isHighlighted = true;
            return node;
          } else {
            node.isHighlighted = false;
            return node;
          }
        } else {
          let selection = highlightElementsArray.find(
            (selectedNode) => selectedNode === node.name
          );
          if (selection) {
            node.isHighlighted = true;
            return node;
          } else {
            node.isHighlighted = false;
            return node;
          }
        }
      });
      setRenderMapData(clonedMap);
    }
  }, [highlightElementsArray]);

  useEffect(() => {
    if (renderMapData) {
      let clonedMap = cloneDeep(renderMapData);
      clonedMap.linkDataArray.map((connection) => {
        let selection = highlightLinksArray.find(
          (selectedConnection) => selectedConnection === connection.id
        );
        if (selection) {
          connection.isHighlighted = true;
          return connection;
        } else {
          connection.isHighlighted = false;
          return connection;
        }
      });
      setRenderMapData(clonedMap);
    }
  }, [highlightLinksArray]);

  // Global Filter Visibility Handler
  useEffect(() => {
    if (renderMapData) {
      let clonedMap = cloneDeep(renderMapData);
      clonedMap?.nodeDataArray?.map((node) => {
        if (node.category !== 'Super') {
          let selection = invisibleNodeArray.find(
            (selectedNode) => selectedNode === node.nodeKey
          );
          if (selection) {
            node.visible = false;
            node.isHighlighted = false;
            return node;
          } else {
            node.visible = true;
            return node;
          }
        } else {
          return node;
        }
      });
      setRenderMapData(clonedMap);
    }
  }, [invisibleNodeArray]);

  /////////////////////
  // THEME FUNCTIONS //
  /////////////////////
  
  // TABLEVIEW NAVIGATION HANDLER
  // ANDY TO DJ: THIS IS THE FUNCTION I NEED HELP FORMATTING CORRECTLY
  function handleTableviewNavigation() {
    store.dispatch(resetCanvasSelection());
    
    const formattedData = formatDataForTableview(multiSelectArray, canvasMapData)
    store.dispatch(setCanvasSelections('tableFormatted', formattedData));
    store.dispatch(toggleFilterTableView(true));
    navigate('/tableview', { state: { from: 'canvasPage' } });
  }

  // SAVE AND LOAD VIEW SUBMENU FUNCTIONS - Displays the Save and Load Submenus
  function handleViewSaveDisplay() {
    setShowDataEditorMenu(false)
    setShowThemeMenu(false);
    setShowFilterMenu(false);
    setShowInspectorMenu(false);
    setShowTableviewRedirectMenu(false);
    setShowViewSaveMenu(!showViewSaveMenu);
  }

  function handleViewLoadDisplay() {
    setShowDataEditorMenu(false)
    setShowThemeMenu(false);
    setShowFilterMenu(false);
    setShowInspectorMenu(false);
    setShowTableviewRedirectMenu(false);
    setShowViewLoadMenu(!showViewLoadMenu);
  }

  // LOAD VIEW FUNCTION - This function gets the view, cross-references it Id's, and sets it as the new map
  function handleViewLoad(view) {
    if (view) {
      const filteredViewData = filterViewData(view);
      setSelectedView(filteredViewData);
      setFilter(false);
      setFilteredMapData(filteredViewData);
      setFilter(true);
      setShowViewLoadMenu(false);
    } else {
      return toast.error('You must select a view to load.');
    }
  }

  function handleExitView() {
    setFilter(false);
    setShowViewLoadMenu(false);
    setSelectedView(null);
    setFilteredMapData(null);
    setRenderMapData(diagramData);
  }

  /////////////////////////////////////////////////////////////////////
  // REVIEW MODE: These functions shift the map into a new set theme //
  /////////////////////////////////////////////////////////////////////
  const handleReviewMode = () => {
    setShowInspectorMenu(false);
    setShowFilterMenu(false);
    setShowTableviewRedirectMenu(false);
    setShowThemeMenu(false);
    setShowDataEditorMenu(false)
    setReviewModeOpen(!reviewModeOpen)
  }
  useEffect(() => {
    // IF REVIEW MODE ON
    // Takes all the Changes Made to the Canvas, and Reformats it Into its Original Setup with Changes Color Coded
    // Each Element has an indicator (from the formatCanvasMapData.js) that indicates whether its added., .edited, or .deleted
    if(reviewModeOpen){
      let colorCodes = {
        added: "#227207",
        edited: "#1560B7",
        deleted: "#fc0505",
        //
        neighborhoodAdd: "#599545",
        neighborhoodEdit: "#6697D0",
        neighborhoodDelete: "#B75858",
        //
        connectionAdd: "#3fb618",
        connectionEdit: "#1560B7",
        connectionDelete: "#fc0505", 
      }
      let newDiagramData = filteredMapData ? cloneDeep(filteredMapData) : cloneDeep(diagramData);
      // Push Deleted Nodes and Neighborhooods into Map Data
      if(canvasMapData.data.branchChangeData.deletes.nodes.length > 0){
        // let neighborhoods = newDiagramData.nodeDataArray.filter(neighborhood => neighborhood.category === "Super")
        // let neighborhoodsWithMissingNodes = neighborhoods.filter(neighborhood => {
        //   canvasMapData.data.branchChangeData.deletes.nodes.forEach(node => {
        //     return neighborhood.nodeIds.includes(node.id)
        //   })
        // })
        newDiagramData.nodeDataArray.push(...canvasMapData.data.branchChangeData.deletes.nodes)
      }
      if(canvasMapData.data.branchChangeData.deletes.neighborhoods.length > 0){
        newDiagramData.nodeDataArray.push(...canvasMapData.data.branchChangeData.deletes.neighborhoods)
      }
      // Map Colors to Nodes and Neighborhoods Based on CRUD Status
      let formattedNodeArray = newDiagramData.nodeDataArray.map(node => {
        if(node.category !== "Super"){
          const updatedNode = {
            ...node,
            color: node.deleted ? colorCodes.deleted : node.added ? colorCodes.added : node.edited ? colorCodes.edited : "white",
            fontColor: node.deleted ? "white" : node.added ? "white" : node.edited ? "white" : node.fontColor,
          } 
          return updatedNode
        } else {
          const updatedNeighborhood = {
            ...node,
            color: node.deleted ? colorCodes.deleted : node.added ? colorCodes.added : node.edited ? colorCodes.edited : "white",
            backgroundColor: node.deleted ? colorCodes.neighborhoodDelete : node.added ? colorCodes.neighborhoodAdd : node.edited ? colorCodes.neighborhoodEdit : "white",
            fontColor: node.deleted ? "white" : node.added ? "white" : node.edited ? "white" : node.fontColor,
            visible: visibleNeighborhoodArray.includes(node.name),
            // _members: node.deleted ? node.nodeKeys.map(node=>node.nodeKey) : node._members
          }
          return updatedNeighborhood
        }
      })

      
      // Push Deleted Connections into Map Data
      if(canvasMapData.data.branchChangeData.deletes.connections.length > 0){
        newDiagramData.linkDataArray.push(...canvasMapData.data.branchChangeData.deletes.connections)
      }
      // Map Colors to Connections based on their CRUD status
      let formattedLinkArray = newDiagramData.linkDataArray.map(connection => {
        const updatedConnection = {
          ...connection,
          color: connection.deleted ? colorCodes.deleted : connection.added ? colorCodes.added : connection.edited ? colorCodes.edited : "black",
          strokeWidth: connection.deleted ? 3 : connection.added ? 3 : connection.edited ? 3 : 2
        }
        return updatedConnection
      })
      newDiagramData.nodeDataArray = formattedNodeArray
      newDiagramData.linkDataArray = formattedLinkArray
      setRenderMapData(newDiagramData)
    // IF REVIEW MODE OFF
    } else {
      let newDiagramData = filteredMapData ? cloneDeep(filteredMapData) : cloneDeep(diagramData);
      let filteredOriginalNodes = diagramData.originalNodeDataArray.filter(oNode => {
        let nodeFound = newDiagramData.nodeDataArray.find(fNode => fNode.nodeKey === oNode.nodeKey)
        if(nodeFound !== undefined){
          return nodeFound
        }
      })
      newDiagramData.nodeDataArray = filteredOriginalNodes.map(node => {
        if(node.category === "Super"){
          const updatedNeighborhood = {
            ...node,
            visible: visibleNeighborhoodArray.includes(node.name),
          }
          return updatedNeighborhood
        } else {
          return node
        }
        
      })
      newDiagramData.linkDataArray = diagramData.originalLinkDataArray;
      let filteredConnections = newDiagramData.linkDataArray.filter(connection => {
        if(canvasMapData.data.branchChangeData.deletes.nodes.filter(deletedNode => deletedNode.nodeKey === connection.to).length === 0
            && canvasMapData.data.branchChangeData.deletes.nodes.filter(deletedNode => deletedNode.nodeKey === connection.from).length === 0){
          return connection
        }
      })
      newDiagramData.linkDataArray = filteredConnections
      setRenderMapData(newDiagramData)
    }
  }, [reviewModeOpen])

  /////////////////////////
  // SELECTION FUNCTIONS //
  /////////////////////////

  // NEIGHBORHOOD LEGEND FUNCTIONS - This function displays the neighborhood legend
  function handleDisplayNeighborhoodLegend() {
    setShowNeighborhoodLegendMenu(!showNeighborhoodLegendMenu);
  }

  // NEIGHBORHOOD SELECTION - Handles Neighborhood Selection
  function handleNeighborhoodSelection(selectedNeighborhoodArray) {
    let clonedMap = cloneDeep(renderMapData);
    clonedMap.nodeDataArray.map((node) => {
      if (node.category === 'Super') {
        if (selectedNeighborhoodArray.includes(node.name)) {
          node.visible = true;
          return node;
        } else {
          node.visible = false;
          node.isHighlighted = false;
          return node;
        }
      } else {
        return node;
      }
    });
    setShowInspectorMenu(false);
    setRenderMapData(clonedMap);
  }

  function handleElementSelection(selected) {
    if (selected.length === 0) {
      setIsNodeSelected(false);
      setMultiSelectActive(false);
      setSelectedNode(null);
      setMultiSelectArray([]);
    } else if (selected.length === 1) {
      if (selected[0].data.type !== 'Connection') {
        setIsNodeSelected(true);
        setMultiSelectActive(false);
        setSelectedNode(selected[0]);
      } else {
        setIsNodeSelected(false);
        setMultiSelectActive(false);
        setSelectedConnection(selected[0]);
      }
    } else if (selected.length > 1) {
      setIsNodeSelected(false);
      setMultiSelectActive(true);
      setMultiSelectArray(selected);
    }
  }

  // VIEW CHANGE FUNCTIONS
  //  These buttons control the rotation
  function handleRotate(buttonClicked) {
    let direction = diagramData.modelData.layoutDirection;
    let rotation = 90;
    if (
      buttonClicked.target.className ===
      'bi bi-arrow-counterclockwise view-change-button'
    ) {
      rotation = -90;
    } else if (
      buttonClicked.target.className ===
      'bi bi-arrow-clockwise view-change-button'
    ) {
      rotation = 90;
    }
    let completeRotation = direction + rotation;
    updateDiagramData((draft) => {
      switch (completeRotation) {
        case -90:
          draft.modelData.layoutDirection = 270;
          draft.nodeDataArray = diagramData.nodeDataArray
          // updateDiagramData(newDiagramData);
          break;
        case 360:
          draft.modelData.layoutDirection = 0;
          draft.nodeDataArray = diagramData.nodeDataArray
          // updateDiagramData(newDiagramData);
          break;
        default:
          draft.modelData.layoutDirection = completeRotation;
          draft.nodeDataArray = diagramData.nodeDataArray
          // updateDiagramData(newDiagramData);
          break;
      }
    });
  }
  // These buttons control the diretional layout
  function handleLayeringOptionChange(buttonClicked) {
    if(buttonClicked.target.id !== diagramData.modelData.layeringOption){
    updateDiagramData((draft) => {
      if (draft.modelData.layeringOption !== buttonClicked.target.id) {
        draft.modelData.layeringOption = buttonClicked.target.id;
        draft.nodeDataArray = renderMapData.nodeDataArray;
      } else if (buttonClicked.target.id === 'LayerLongestPathSource') {
        draft.modelData.layeringOption = 'resetLayerLongestPathSource';
        draft.nodeDataArray = renderMapData.nodeDataArray;
      } else if (buttonClicked.target.id === 'LayerLongestPathSink') {
        draft.modelData.layeringOption = 'resetLayerLongestPathSink';
        draft.nodeDataArray = renderMapData.nodeDataArray;
      } else {
        draft.modelData.layeringOption = 'resetLayerLongestPathSource';
        draft.nodeDataArray = renderMapData.nodeDataArray;
      }
    });
      handleModelChange(diagramData);
    }
  }

  useEffect(() => {
    if (renderMapData) {
      if (renderMapData.nodeDataArray.length === 0) {
        if (canvasMapData.selectedBranch.name === 'published' && !filtered) {
          toast.error(
            'You may need to merge your edit_draft branch into your Published branch. Check to make sure your edit_draft branch has nodes.'
          );
        } else if (
          canvasMapData.selectedBranch.name === 'edit_draft' &&
          !filtered
        ) {
          toast.error(
            'You need to upload nodes into youe edit_draft branch through the Map Upload section - under the Projects tab above.'
          );
        } else if (!filtered) {
          toast.error('This project has no nodes to render into a map.');
        } else if (filtered === true) {
          toast.error('No nodes were detected in this filter.');
        }
      }

      if (renderMapData.nodeDataArray?.length > 1200) {
        setShowFilterMenu(true);
        setOpenGlobalSidebar(true);
        toast.error('Please filter your map to under 1200 nodes.');
      }
    }
  }, [renderMapData]);

  ////////////
  // RETURN //
  ////////////
  if (!renderMapData) {
    return (
      <div
        style={{ marginTop: '40vh', fontSize: '28px' }}
        className="load-message"
      >
        Rendering Data into a Map...
      </div>
    );
  }
  if (renderMapData !== undefined) {
    return (
      <div className="canvas-page-container">
        {/* ///////////////////////////// */}
        {/* /////// UI INTERFACE //////// */}
        {/* ///////////////////////////// */}
        <CanvasInterface
          renderMapData={renderMapData}
          handleInspectionToolDisplay={handleInspectionToolDisplay}
          handleFilterDisplay={handleFilterDisplay}
          openGlobalSidebar={openGlobalSidebar}
          handleGlobalSidebarDisplay={handleGlobalSidebarDisplay}
          handleShowDataEditorMenu={handleShowDataEditorMenu}
          handleThemeMenuDisplay={handleThemeMenuDisplay}
          handleTableviewRedirectDisplay={handleTableviewRedirectDisplay}
          handleCanvasTutorialVisibility={handleCanvasTutorialVisibility}
          handleViewSaveDisplay={handleViewSaveDisplay}
          handleViewLoadDisplay={handleViewLoadDisplay}
          handleCanvasExportDisplay={handleCanvasExportDisplay}
          handleReviewMode={handleReviewMode}
          handleRotate={handleRotate}
          handleLayeringOptionChange={handleLayeringOptionChange}
          nodeAlignment={diagramData.modelData.layeringOption}
          handleDisplayNeighborhoodLegend={handleDisplayNeighborhoodLegend}
          isNodeSelected={isNodeSelected}
          multiSelectArray={multiSelectArray}
          showDataEditorMenu={showDataEditorMenu}
          showThemeMenu={showThemeMenu}
          showFilterMenu={showFilterMenu}
          // LEAVE THIS FOR WHEN WE NEED TO FORCE REDUCE THE MAP SIZE FOR TESTING
          // showFilterMenu={(diagramData?.nodeDataArray?.length > 1200) ? true : showFilterMenu}
          showInspectorMenu={showInspectorMenu}
          showViewSaveMenu={showViewSaveMenu}
          showViewLoadMenu={showViewLoadMenu}
          showMapDownloadModal={showMapDownloadModal}
          reviewModeOpen={reviewModeOpen}
          showNeighborhoodLegendMenu={showNeighborhoodLegendMenu}
          filtered={filtered}
        />
        {/* //////// DATA EDITOR ///////*/}
        <CanvasDataEditor
          handleCrud={handleCrud}
          branchChangeData={canvasMapData.data.branchChangeData}
          multiSelectArray={multiSelectArray}
          show={showDataEditorMenu}
          setShow={setShowDataEditorMenu}
          handleShowDataEditorMenu={handleShowDataEditorMenu}
          renderMapData={renderMapData}
          handleTableviewNavigation={handleTableviewNavigation}
        />
        {/* //////// FILTER SUBMENU ///////*/}
        <CanvasFilter
          handleFilterDisplay={handleFilterDisplay}
          diagramData={diagramData}
          diagramAccess={diagramAccess}
          showFilterMenu={showFilterMenu}
          setShowFilterMenu={setShowFilterMenu}
          filteredMapData={filteredMapData}
          renderMapData={renderMapData}
          setRenderMapData={setRenderMapData}
          handleFilteredMapData={handleFilteredMapData}
          setFilter={setFilter}
          filtered={filtered}
          globalKeywordInput={globalKeywordInput}
          setGlobalKeywordInput={setGlobalKeywordInput}
        />
        {/* //////// THEME SUBMENU ////////// */}
        <CanvasThemes
          diagramData={diagramData}
          diagramAccess={diagramAccess}
          filteredMapData={filteredMapData}
          setFilteredMapData={setFilteredMapData}
          setRenderMapData={setRenderMapData}
          handleThemeMenuDisplay={handleThemeMenuDisplay}
          showThemeMenu={showThemeMenu}
          selectedNode={selectedNode}
          multiSelectActive={multiSelectActive}
          multiSelectArray={multiSelectArray}
          updateDiagramData={updateDiagramData}
          setHighlightElementsArray={setHighlightElementsArray}
        />
        {/* //////// INSPECTOR SUBMENU ///////*/}
        <CanvasInspectorTool
          handleInspectionToolDisplay={handleInspectionToolDisplay}
          canvasMapData={canvasMapData}
          selectedNode={selectedNode}
          show={showInspectorMenu}
          setShow={setShowInspectorMenu}
          selectionResources={selectionResources}
        />
        {/* //////// TABLEVIEW REDIRECT CONFIRMATION ///////*/}
        <CanvasTableviewRedirect
          handleTableviewRedirectDisplay={handleTableviewRedirectDisplay}
          selectedNode={selectedNode}
          multiSelectArray={multiSelectArray}
          show={showTableviewRedirectMenu}
          setShow={setShowTableviewRedirectMenu}
          handleTableviewNavigation={handleTableviewNavigation}
        />
        {/* //////// CANVAS TUTORIAL ///////// */}
        <CanvasTutorial
          show={showCanvasTutorial}
          setShow={setShowCanvasTutorial}
        />
        {/* //////// SAVE AND LOAD VIEWS */}
        <CanvasSaveView
          showViewSaveMenu={showViewSaveMenu}
          onShowViewSaveMenu={setShowViewSaveMenu}
          setShowViewSaveMenu={setShowViewSaveMenu}
          diagramAccess={diagramAccess}
          setRenderMapData={setRenderMapData}
          renderMapData={renderMapData}
          selectedProject={canvasMapData.selectedProject}
          selectedBranch={canvasMapData.selectedBranch}
          views={views}
        />
        {/* //////// SAVE AND LOAD VIEWS */}
        <CanvasLoadView
          showViewLoadMenu={showViewLoadMenu}
          onShowViewLoadMenu={setShowViewLoadMenu}
          selectedProject={canvasMapData.selectedProject}
          selectedBranch={canvasMapData.selectedBranch}
          selectedView={selectedView}
          views={views}
          handleViewLoad={handleViewLoad}
          handleExitView={handleExitView}
        />
        {/* CANVAS IMAGE EXPORT MENU */}
        <CanvasExportMap
          showMapDownloadModal={showMapDownloadModal}
          setShowMapDownloadModal={setShowMapDownloadModal}
          diagramAccess={diagramAccess}
        />
        {/* /////// NEIGHBORHOOD LEGEND SUBMENU ///////// */}
        <CanvasNeighborhoodLegend
          neighborhoodLegendArray={neighborhoodLegendArray}
          setVisibleNeighborhoodArray={setVisibleNeighborhoodArray}
          showNeighborhoodLegendMenu={showNeighborhoodLegendMenu}
        />
        {/* ///////////////////////////// */}
        {/* ///// CANVAS COMPONENT ////// */}
        {/* ///////////////////////////// */}
        <div className="canvas-section">
          <div className="row">
            <div
              style={{ paddingRight: 0 }}
              className={openGlobalSidebar ? 'col-2' : 'global-sidebar-off'}
            >
              <div
                className={`global-filter-sidebar-container  
                ${
                  openGlobalSidebar
                    ? 'open-global-sidebar'
                    : 'close-global-sidebar'
                }`}
              >
                <GlobalSidebar
                  handleFilterDisplay={handleFilterDisplay}
                  handleGlobalSidebarDisplay={handleGlobalSidebarDisplay}
                  renderMapData={renderMapData}
                  invisibleNodeArray={invisibleNodeArray}
                  setInvisibleNodeArray={setInvisibleNodeArray}
                  highlightElementsArray={highlightElementsArray}
                  setHighlightElementsArray={setHighlightElementsArray}
                  highlightLinksArray={highlightLinksArray}
                  setHighlightLinksArray={setHighlightLinksArray}
                  visibleNeighborhoodArray={visibleNeighborhoodArray}
                  setVisibleNeighborhoodArray={setVisibleNeighborhoodArray}
                  openElementsArray={openElementsArray}
                  setOpenElementsArray={setOpenElementsArray}
                  globalKeywordInput={globalKeywordInput}
                />
              </div>
            </div>
            <div
              style={{ paddingLeft: 0 }}
              className={openGlobalSidebar ? 'col-10' : 'col-12'}
            >
              <div className="gojs-wrapper-div">
                {renderMapData.nodeDataArray.length > 0 &&
                renderMapData.nodeDataArray.length < 1200 ? (
                  //////////////////
                  // Heavy Canvas //
                  //////////////////
                  <Canvas
                    nodeDataArray={renderMapData.nodeDataArray}
                    linkDataArray={renderMapData.linkDataArray}
                    modelData={diagramData.modelData}
                    // modelData={filteredMapData !== null ? diagramData.modelData : filteredMapData.modelData}
                    onModelChange={handleModelChange}
                    handleElementSelection={handleElementSelection}
                    neighborhoodLegendArray={neighborhoodLegendArray}
                    setMultiSelectActive={setMultiSelectActive}
                    setMultiSelectArray={setMultiSelectArray}
                    setIsNodeSelected={setIsNodeSelected}
                    setSelectedNode={setSelectedNode}
                    skipsDiagramUpdate={renderMapData.skipsDiagramUpdate}
                    setDiagramAccess={setDiagramAccess}
                    reviewModeOpen={reviewModeOpen}
                  />
                ) : (
                  <div className="empty-canvas"></div>
                )}
              </div>
            </div>
            <div className="row"></div>
          </div>
        </div>
      </div>
    );
  }
};

CanvasPage.propTypes = {
  canvasMapData: PropTypes.object,
  handleRefresh: PropTypes.func,
};

export default CanvasPage;