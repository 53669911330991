/**
 * @summary NeighborhoodCollectionPage.js
 * @file parent component for NeighborhoodCollectionGrid.js
 * @returns {JSX}
 * @usedBy tableviewLayout.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import NeighborhoodCollectionGrid from './NeighborhoodCollectionGrid';
import PropTypes from 'prop-types';

const NeighborhoodCollectionPage = ({ setShow, headers, setSelectedElements, selectedElements }) => {
  // Forces re-render //
  const [refresh, setRefresh] = useState(false);
  return (
    <div>
      <NeighborhoodCollectionGrid
        setRefresh={setRefresh}
        setShow={setShow}
        refresh={refresh}
        headers={headers}
        selectedElements={selectedElements}
        setSelectedElements={setSelectedElements}
      />
    </div>
  );
};

NeighborhoodCollectionPage.propTypes = {
  setShow: PropTypes.func,
  headers: PropTypes.object,
  setSelectedElements: PropTypes.func,
  selectedElements: PropTypes.object,
};

export default NeighborhoodCollectionPage;
