/**
 * @summary ConnectionForm.js
 * @file Connection form component for creating, editing, or deleting a connection
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from '../../../store/store';
import ConnectionFormHeader from './ConnectionFormHeader';
import Accordion from '../../../shared/accordian/Wrapper';
import Panel from '../../../shared/accordian/Panel';
import ConnectionAttributeSettings from './ConnectionAttributeSettings';
import { editConnection } from 'store/connections/ConnectionActions';
import { useNavigate } from 'react-router-dom';

const messageOptions = {
  messsageType: {
    success: 'Success'
  },
  messages: {
    successOnDelete: 'Connection Deleted',
    errorOnDelete: 'Unable to Delete Connection',
    errorOnSave: 'Unable to Save Connection',
    underConstruction: 'This Function is Under Construction'
  }
};

const ConnectionForm = () => {
  const userObj = useSelector((state) => state.authReducer.userObj);
  const selectedBranch = useSelector((state) => state.authReducer.userObj.selectedBranch[0]);
  const selectedProject = useSelector((state) => state.authReducer.userObj.selectedProject[0]);
  const connection = useSelector((state) => state.connectionReducer.selectedData[0]);
  const connectionArray = useSelector((state) => {
    return Object.values(state.connectionReducer)
  }, shallowEqual);

  const navigate = useNavigate();
  const [profileDetails, setProfileDetails] = useState([]);
  const [attrsLoaded, setAttrsLoaded] = useState(false);
  const projectChange = useRef(selectedProject);
  const branchChange = useRef(selectedBranch);
  const [cloneData, setCloneData] = useState(null);
  const [selectedClone, setSelectedClone] = useState(null);
  const [cloneConfirmationVisible, setCloneConfirmationVisible] = useState(false);

  const handleCloneSelect = () => {
    setCloneData(selectedClone)
    store.dispatch(
      getNode(
        userObj.selectedProject[0].id,
        userObj.selectedProject[0].id,
        selectedClone.id
      )
    );
    setCloneConfirmationVisible(false)
  }

  useEffect(() => {
    // User changes the profile project and saves we send them to tableview
    if (selectedProject !== projectChange.current) {
      toast.error(
        `${connection?.name} does not exist on ${selectedProject.name}`
      );
      navigate('/tableview');
    } else if (selectedBranch !== branchChange.current) {
      toast.error(
        `${connection?.name} does not exist on ${selectedBranch.name}`
      );
      navigate('/tableview');
    }
  }, [selectedProject, selectedBranch]);

  const onSave = () => {
    const json = {
      id: connection?.id,
      name: connection?.name,
      sourceNodeId: connection?.origin_node_id,
      destinationNodeId: connection?.destination_node_id,
      connectionType: connection?.connectionType,
      projectId: userObj.selectedProject[0].id,
      branchId: userObj.selectedBranch[0].id,
      settings: {
        profileDetails: []
      }
    };
    profileDetails.forEach((attr) =>
      json.settings.profileDetails.push({
        attrId: attr.attributeId,
        connectionAttrId: attr.connAttrId,
        value: attr.selectedValue.map((val, idx) => ({
          id: val.metaDataId ? val.metaDataId[idx] : undefined,
          connectionValueId: val.valueId,
          value: val.name,
          connectionAttrId: val.connectionAttrId
        }))
      })
    );

    store
      .dispatch(editConnection(json))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message_type != 'success') {
            if (
              res.data === 'this conection is not checked out on this Branch'
            ) {
              toast.error(
                `Connection: ${connection.name} is not checked out on Branch: ${userObj.selectedBranch[0].name}`
              );
            } else if (res.data === 'current User does not own this branch') {
              toast.error(
                `${userObj.screenName} does not own this Branch: ${userObj.selectedBranch[0].name}`
              );
            } else if (res.data === 'branch not in draft status') {
              toast.error(
                `Branch ${userObj.selectedBranch[0].name} not in "draft" status`
              );
            } else {
              toast.error(
                `An unexpected error occurred while updating connection: ${connection.name}`
              );
            }
          }
        } else {
          toast.success(`Connection: ${connection.name} Updated`);
        }
      })
      .catch(() => {
        toast.error(
          `An unexpected error occurred while updating Connection: ${connection.name}`
        );
      });
  };

  return (
    <>
      <ConnectionFormHeader
        userObj={userObj}
        connection={connection}
        onSave={onSave}
        connectionName={connection?.name}
        connectionArray={connectionArray}
        handleCloneSelect={handleCloneSelect}
        cloneConfirmationVisible={cloneConfirmationVisible}
        setCloneConfirmationVisible={setCloneConfirmationVisible}
        selectedClone={selectedClone}
        setSelectedClone={setSelectedClone}
      />
      <Accordion>
        <Panel
          title="Attribute Settings"
          help_code={'ATTRIBUTE'}
          panel_type={'attribute_settings'}
          body={
            <ConnectionAttributeSettings
              userObj={userObj}
              connection={connection}
              profileDetails={profileDetails}
              setProfileDetails={setProfileDetails}
              setAttrsLoaded={setAttrsLoaded}
              attrsLoaded={attrsLoaded}
              cloneData={cloneData}
            />
          }
        />
        <Panel
          title="Styles"
          help_code={'STYLES'}
          panel_type={'styles'}
          body={<h1>not yet implemented</h1>}
        />
        <Panel
          title="Notes"
          help_code={'NOTES'}
          panel_type={'notes'}
          body={<h1>not yet implemented</h1>}
        />
        <Panel
          title="Change History"
          help_code={'CHANGEHISTORY'}
          panel_type={'change_history'}
          body={<h1>not yet implemented</h1>}
        />
      </Accordion>
    </>
  );
};

export default ConnectionForm;
