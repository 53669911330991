/**
 * @summary InspectorTool.js
 * @file Parent Modal for Adding a Node on Canvas
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2024
 * @lastUpdated 8/8/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { MultiSelect } from "@progress/kendo-react-dropdowns";

const AddNodeCanvasModal = ({
    handleAddModalVisibility,
    handleCrud,
    handleTableviewNavigation
  }) => {
    // REDUX DATA
    const userObj = useSelector((state) => state.authReducer.userObj);
    const reduxNodes = useSelector((state) => state.nodeReducer);
    const attributeValues = useSelector((state) => {
        return state.projectSetupReducer.nodesSelectedValues
    });
    
    // MODAL SELECTIONS BY USER
    // User Changes these States Throughout the Modal
    // Attributes are handles in states below, and formatted into saveNodeAttributes through a useEffect
    const [saveNodeKey, setSaveNodeKey] = useState(null)
    const [saveNodeName, setSaveNodeName] = useState(null)
    const [saveNodeDescription, setSaveNodeDescription] = useState(null)
    const [saveNodeAttributes, setSaveNodeAttributes] =useState({
        details: [],
        metadata: [],
        taxonomies: [],
        flags: []
    })

    // ATTRIBUTE STATES
    // These Set the Correct Available Attributes and Handle Selections
    // Attributes are formatted in a useEffect and set into "saveNodeAttributes" above
    const [availableSubjects, setAvailableSubjects] = useState([])
    const [availableFrameworks , setAvailableFrameworks] = useState([])
    const [selectedSubjects, setSelectedSubjects] = useState([])
    const [selectedFrameworks, setSelectedFrameworks] = useState([])
    // USE EFFECT FOR GETTING ATTRIBUTES
    // Filters Subjects and Frameworks from Redux Attributes, and Formats them for save-ability
    useEffect(() => {
        if(attributeValues.filter(setting => setting.name === "subject")){
            const subject = attributeValues.filter(setting => setting.name === "subject")[0];
            if(subject){
                let formattedSubjects = subject.value.map(subjectValues => {
                    return ({
                        attrId: subject.id,
                        value: [
                            {
                                id: subjectValues.id,
                                value: subjectValues.name
                            }
                        ]
                    })
                })
                setAvailableSubjects(formattedSubjects)
            }
            
        }
        if(attributeValues.filter(setting => setting.name === "content_framework_type")){
            const framework = attributeValues.filter(setting => setting.name === "content_framework_type")[0];
            if(framework){
                let formattedFrameworks = framework.value.map(frameworkValues => {
                    return ({
                        attrId: framework.id,
                        value: [
                            {
                                id: frameworkValues.id,
                                value: frameworkValues.name
                            }
                        ]
                    })
                })
                setAvailableFrameworks(formattedFrameworks)
            }
        }
    }, [])

    // FORMAT ATTRIBUTES INTO NODE
    useEffect(()=>{
        let settings = {
            details: [],
            metadata: [],
            taxonomies: [],
            flags: []
        }
        if(selectedSubjects){
            settings.metadata.push(...selectedSubjects)
        }
        if(selectedFrameworks){
            settings.metadata.push(...selectedFrameworks)
        }
        setSaveNodeAttributes(settings)
    },[selectedSubjects,selectedFrameworks])
    
    // NODE OBJECT THAT GETS SAVED
    const jsonNode = {
        action: 'create',
        elementType: 'node',
        source: 'Canvas Created',
        projectId: userObj.selectedProject[0].id,
        branchId: userObj.selectedBranch[0].id,
        status: '',
        id: -1,
        nodeKey: saveNodeKey,
        name: saveNodeName,
        description: saveNodeDescription || '',
        nodeMediaIds: [],
        settings: saveNodeAttributes
    }
    // HANDLE SAVE NODE
    const handleSaveNewNode = () => {
        let reduxNodeArray = Object.values(reduxNodes);
        if(saveNodeKey && saveNodeName){
            if(reduxNodeArray.find(node => {return node.nodeKey === saveNodeKey}) === undefined){
                if(reduxNodeArray.find(node => {return node.name === saveNodeName}) === undefined){
                    handleCrud(jsonNode)
                    handleAddModalVisibility()
                } else {
                    return toast.error("Duplicate Node Name")
                }
            } else {
                return toast.error("Duplicate Node Key")
            }
        } else {
            if(!saveNodeKey){
                return toast.error("Enter a Node Key")
            }
            if(!saveNodeName){
                return toast.error("Enter a Node Name")
            }
        }
    }

    // NODE KEY INPUTS
    const handleNodeKeyInputChange = (e) => {
        setSaveNodeKey(e.target.value)
    }

    // NODE NAME INPUTS
    const handleNodeNameInputChange = (e) => {
        setSaveNodeName(e.target.value)
    }

    // NODE DESCRIPTION INPUTS
    const handleNodeDescriptionInputChange = (e) => {
        setSaveNodeDescription(e.target.value)
    }

    // SUBJECT INPUTS
    const handleSubjectInputChange = (attributeInputs) => {
        if(attributeInputs.length > 0){
            let formattedInputs = attributeInputs.map(attributeInput => {
                let foundSubject = availableSubjects.find(subject => subject.value[0].id === attributeInput.id)
                let formattedInput = {
                    attrId: foundSubject.attrId,
                    value: [
                        {
                            id: attributeInput.id,
                            value: attributeInput.value
                        }
                    ]
                }
                return formattedInput;
            })
            setSelectedSubjects(formattedInputs)
        } else {
            setSelectedSubjects([])
        }
    }

    // FRAMWORK INPUTS
    const handleFrameworkInputChange = (attributeInputs) => {
        if(attributeInputs.length > 0){
            let formattedInputs = attributeInputs.map(attributeInput => {
                let foundFramework = availableFrameworks.find(framework => framework.value[0].id === attributeInput.id)
                let formattedInput = {
                    attrId: foundFramework.attrId,
                    value: [
                        {
                            id: attributeInput.id,
                            value: attributeInput.value
                        }
                    ]
                }
                return formattedInput;
            })
            setSelectedFrameworks(formattedInputs)
        } else {
            setSelectedFrameworks([])
        }
    }

    // FORMAT ATTRIBUTE DROPDOWNS AND INPUTS - They're a little complex to unpack
    let subjectDropdownOptions = availableSubjects.length > 0 ? availableSubjects?.map(subject => {
        return subject.value[0]
    }) : []
    let subjectInputs = selectedSubjects.length > 0 ? selectedSubjects.map(subject => {
        let subjectInput = {
            value: subject.value[0].value,
            id: subject.value[0].id,
        }
        return subjectInput
    }) : []
    let frameworkDropdownOptions = availableFrameworks.length > 0 ? availableFrameworks?.map(subject => {
        return subject.value[0]
    }) : []
    let frameworkInputs = selectedFrameworks.length > 0 ? selectedFrameworks.map(framework => {
        let frameworkInput = {
            value: framework.value[0].value,
            id: framework.value[0].id,
        }
        return frameworkInput
    }) : []
    
    // RETURN
    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <div className='modal-header-text'>
                        Add New Node
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row '>
                    <div className='col-12 '>
                        <div className='instructional-blue pt-3 pb-3'>
                            <div className='col-12'>
                                Enter the data for your node. Make sure to include a Node Key and Name. Redirect to Tableview for more configuration options on the tableview page.
                            </div>
                            
                        </div>
                        <div className='ps-3 pb-4 mt-4'>
                            <div className='row mt-1'>
                                
                                <div className='col-9'>
                                    <div className='ms-3 pt-3 bold-instruction'>
                                            Advanced Settings will open a new tab where you can 
                                            customize your node with more in-depth options.
                                    </div>
                                </div>
                                <div className="col-3 pe-5">   
                                    <button onClick={handleTableviewNavigation} className="btn btn-success w-100 w-75 h-75">Redirect to Tableview</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container pb-5 px-4'>
                    <div className='col-12'>
                        <div className="data-editor-section">
                            <div className="col-12 data-editor-subheader">
                                Node Key
                            </div>
                            <div className="col-12">
                            
                                    <input 
                                        placeholder="Enter a Node Key" 
                                        onChange={handleNodeKeyInputChange} 
                                        type="text">
                                    </input>            
                            </div>
                        </div>
                        <div className="data-editor-section">
                            <div className="col-12 data-editor-subheader">
                                Node Name
                            </div>
                            <div className="col-12">
                                    <input 
                                        placeholder="Enter a Node Name" 
                                        onChange={handleNodeNameInputChange} 
                                        type="text">
                                    </input>            
                            </div>
                        </div>
                        <div className="data-editor-section">
                            <div className="col-12 data-editor-subheader">
                                Node Description
                            </div>
                            <div className="col-12">
                                <textArea 
                                    style={{ maxHeight: "300px", maxWidth: "100%"}}
                                    placeholder="Enter a Node Description" 
                                    className='data-editor-description-input'
                                    wrap="soft" 
                                    onChange={handleNodeDescriptionInputChange} 
                                    type="text">
                                </textArea>                                         
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="data-editor-section">
                                    <div className='col-2 d-inline data-editor-subheader pe-1'>
                                        Subject: 
                                    </div>
                                    <MultiSelect
                                        data={subjectDropdownOptions}
                                        style={{color: "black"}}
                                        dataItemKey='id'
                                        textField='value'
                                        value={subjectInputs}
                                        placeholder={'Enter the Subjects You Would Like to Add.'}
                                        onChange={(e) => {
                                            handleSubjectInputChange(e.target.value)
                                        }}
                                    >
                                    </MultiSelect>
                                    
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="data-editor-section">
                                    <div className='col-2 d-inline data-editor-subheader pe-1'>
                                        Content Framework: 
                                    </div>
                                    <MultiSelect
                                        data={frameworkDropdownOptions}
                                        style={{color: "black"}}
                                        dataItemKey='id'
                                        textField='value'
                                        value={frameworkInputs}
                                        placeholder={'Enter the Frameworks You Would Like to Add.'}
                                        onChange={(e) => {
                                            handleFrameworkInputChange(e.target.value)
                                        }}
                                    >
                                    </MultiSelect>
                                </div>    
                            </div>      
                        </div>          
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <button 
                                className="btn btn-primary btn-sm text-white"
                                onClick={handleSaveNewNode}
                            >
                                Save and Close
                            </button>
                        </div>
                        <div className='col-6'>
                            <button 
                                className="btn btn-danger btn-sm text-white" 
                                onClick={handleAddModalVisibility}
                            >
                                Cancel
                            </button>
                        </div>             
                    </div>
                </div>
            </Modal.Footer>
        </>
    );
} 

AddNodeCanvasModal.propTypes = {
  handleAddModalVisibility: PropTypes.func,
  handleTableviewNavigation: PropTypes.func,
  handleCrud: PropTypes.func,
};

export default AddNodeCanvasModal;