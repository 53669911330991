/**
 * @summary formatDataForTableview.js
 * @file This util formats the data back to the official raw data as part of the redirect feature that let's users transfer their selections from the Canvas to Tableview
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 02/16/2024
 * @lastUpdated 02/16/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

const formatDataForTableview = (
    multiSelectArray,
    canvasMapData
) => {
    let reduxNodes = [];
    let reduxNeighborhoods = [];
    let reduxConnections = [];

    multiSelectArray.forEach((element) => {
      if (element.data.category !== 'Super') {
        let formattedNode = canvasMapData.rawData.nodes.find((node) => {
          if (node.nodeKey === element.data.nodeKey) {
            return node;
          }
        });
        if (formattedNode) {
          reduxNodes.push(formattedNode);
        }
      } else {
        let formattedNeighborhood = canvasMapData.rawData.neighborhoods.find(
          (neighborhood) => {
            if (neighborhood.id === element.data.nodeKey) {
              neighborhood.nodeKeys.forEach((memberNode) => {
                let memberMatch = canvasMapData.rawData.nodes.find((node) => {
                  if (node.nodeKey === memberNode.nodeKey) {
                    return node;
                  }
                });
                reduxNodes.push(memberMatch);
              });
              return neighborhood;
            }
          }
        );
        if (formattedNeighborhood) {
          reduxNeighborhoods.push(formattedNeighborhood);
        }
      }
    });

    reduxNodes.forEach((node) => {
      let formattedConnection = canvasMapData.rawData.connections.find(
        (connection) => {
          if (
            connection.destinationNodeKey === node.nodeKey ||
            connection.sourceNodeKey === node.nodeKey
          ) {
            if (!reduxConnections.includes(connection)) {
              return reduxConnections;
            }
          }
        }
      );
      if (formattedConnection) {
        reduxConnections.push(formattedConnection);
      }
    });

    let tableviewReduxObj = {
      nodes: reduxNodes,
      neighborhoods: reduxNeighborhoods,
      connections: reduxConnections
    };
    return tableviewReduxObj

}

export default formatDataForTableview;
