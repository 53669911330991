/**
 * @summary InspectorTool.js
 * @file Modal for Containing Injuey Data for Selected Element(s). Opened through CanvasInterface.js After at least one Element is Selected
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useState } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { addComment, updateComment, deleteNodeComment } from 'store/nodes/NodeActions';
import { useSelector } from 'react-redux';
import store from 'store/store';
import { toast } from 'react-toastify';
import axios from 'axios';

const InspectorTool = ({
  selectedNode,
  selectionResources,
  show, 
  setShow 
}) => {
  const projectId = useSelector((state) => state.authReducer.userObj.selectedProject[0].id)
  const branchId = useSelector((state) => state.authReducer.userObj.selectedBranch[0].id)
  const assessmentProgramId = useSelector((state) => state.authReducer.userObj.currentAssessmentProgram)
  const screenName = useSelector((state) => state.authReducer.userObj.screenName)
  const [selectedComment, setSelectedComment] = useState(null)
  const [showNewForm, setShowNewForm] = useState(false)
  const [newComment, setNewComment] = useState({
    content: '',
    projectId,
    branchId,
    assessmentProgramId,
    nodeId: ''
  })

  if (selectedNode) {
    const { data: node } = selectedNode

    // ATTRIBUTE FORMATTER
    let stringAttributes = []
    let booleanAttributes = [] 
    node?.settings?.attributes?.forEach(attribute => {
        let attributeObj = {
            key: node.nodeKey.toString() + "-" + Object.keys(attribute).toString(),
            name: Object.keys(attribute)[0].replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            value: attribute[Object.keys(attribute)].map(obj => obj.value.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()))
        }
        if(attributeObj?.value[0] === "True" || attributeObj?.value[0] === "False"){
            booleanAttributes.push(attributeObj)
        } else {
            stringAttributes.push(attributeObj)
        }
        return attributeObj
    })
    let allAttributes = stringAttributes.concat(booleanAttributes)

    const resetComment = () => {
      setNewComment({
        content: '',
        projectId,
        branchId,
        assessmentProgramId,
        nodeId: ''
      })
    }

    const updateNodeComment = async (commentId) => {
      try {
        const { data: { comment } } = await store.dispatch(updateComment({ ...newComment, nodeId: node.id, commentId: commentId }))
        const commentIdx = node.comments.findIndex((commentObj) => commentObj.id === comment.id)
        if (commentIdx > -1) {
          node.comments.splice(commentIdx, 1, { ...node.comments[commentIdx], content: newComment.content, nodeId: node.id, id: comment });
          setSelectedComment(null)
          resetComment()
        }
      } catch(err) {
        toast.error('Error updating comment')
      }
    }

    const addNewComment = async () => {
      try {
        const { data: { comment } } = await store.dispatch(addComment({ ...newComment, nodeId: node.id }))
        node.comments.push(comment)
        setShowNewForm(false)
        resetComment()
      } catch(err) {
        toast.error('Error adding comment')
      }
    }

    const deleteComment = async (commentObj) => {
      try {
        const { data: { id } } = await store.dispatch(deleteNodeComment({ ...commentObj, nodeId: node.id, commentId: commentObj.id, projectId, branchId }))
        const commentIdx = node.comments.findIndex((commentObj) => commentObj.id === id)
  
        if (commentIdx > -1) {
          node.comments.splice(commentIdx, 1);
          resetComment()
        }
      } catch(err) {
        toast.error('Error deleting comment')
      }
    }

    function handleOpenResource(e, params) {
      e.preventDefault();
      axios
        .post('/api/signedgeturl', params)
        .then((res) => {
          if (res.data.success) {
            const dlink = document.createElement('a');
            dlink.target = '_blank';
            dlink.href = res.data.data;
            dlink.onclick = () => window.URL.revokeObjectURL(res.data.data);
            dlink.click();
            dlink.remove();
          }
        })
        .catch((error) => {
          if (error?.response?.data) {
            return toast.error("Failed to Open Resource");
          } else {
            return toast.error("Failed to Open Resource");
          }
        });
    }    
    function removeHtmlTags(input) {
      return input.replace(/<[^>]+>/g, '');
    }
    let formattedDescription = removeHtmlTags(node?.description)
    if (node !== null) {
      return (
        <>
          <Modal show={show} backdrop="static" centered size="lg">
            <Modal.Header className='inspector-header'>
              Element Inspector
              <div
                className="bi bi-x submenu-close-x"
                onClick={() => {setSelectedComment(null)
                  resetComment()
                  setShow(false)
                }}
              >
            </div>
            </Modal.Header>
            <Modal.Body>
            <div className='inspector-id'>
                <div className="row" >
                  <div className="inspector-data-field" >
                    <div className='inspector-label'>
                      Key:
                    </div>
                    <div className='inspector-data'>
                      {node.nodeKey}
                    </div>
                  </div>
                </div>
            <div>
            <div className='inspector-upper-details'>
                  <div className="row">
                    <div className="inspector-data-field">
                      <div className='inspector-label'>
                        Title:
                      </div>
                      <div className='inspector-data'>
                        {node?.name}
                      </div>
                      
                    </div>
                  </div>
                  <div className="row">
                    <div className="inspector-data-field">
                      <div className='inspector-label'>
                        Description:
                      </div>
                      <div className='inspector-data'>
                        {formattedDescription}
                      </div>
                      
                    </div>
                  </div>
                  <div className="row">
                    <div className="inspector-data-field">
                      <div className='inspector-label'>
                        Checked Out By:
                      </div>
                      <div className='inspector-data'>
                        {node?.checkedOutBy}
                      </div>
                      
                    </div>
                  </div>
                  <div className='row'>
                    <div className="inspector-data-field">
                      <div className='inspector-label'>
                        Attributes:
                      </div>
                        {!node?.settings?.attributes?.length ?
                          <div>None Available</div>                        
                        :
                        allAttributes?.map(attribute => {
                            return(
                                <>
                                    <div key={attribute.key} className='inspector-list'>
                                        <li>
                                            <span className='attribute-label'>{attribute.name}</span>: <span className='inspector-data'>{attribute.value}</span>
                                        </li>
                                    </div> 
                                </>
                            )
                        })     
                        }
                      
                    </div>
                  
                  </div>
                  <div className="row">
                    <div className="inspector-data-field">
                      <div className='inspector-label'>
                        Resources:
                      </div>
                      <div className='inspector-data'>
                        {selectionResources.length > 0 ? 
                          selectionResources.map(resource => {
                            const params = {
                              params: {
                                mediaPath: resource?.filelocation
                              }
                            }; 
                            return(
                              <div key={resource.id} className='row'>
                                {/* {resource} */}
                                <div className='inspector-list'>
                                  {/* QUESTION FOR DJ ABOUT THIS */}
                                    <li>
                                      <a 
                                        href={"#"} 
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                          handleOpenResource(e, params);
                                        }}
                                        >
                                        {resource.name}
                                      </a>
                                    </li>
                                </div>
                              </div>
                            )
                          })
                          :
                          <div>None Available</div>
                        }
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group row  d-flex" ></div>
              
              <div className="form-group" id="comment-grey">
                <div className='comments-header' id="comment-grey">Comments</div>
                {
                  node.comments ? node.comments.map((comment) => (
                    <Card key={`node-comment-${comment.id}`} style={{ margin: '4px 4px 0px 4px' }}>
                      <Card.Body>
                        {selectedComment && comment.id === selectedComment.id ? (
                          <>
                            <textarea 
                              value={newComment.content}
                              style={{ width: '500px' }}
                              onChange={(e) => {
                                setNewComment({
                                  ...newComment,
                                  content: e.target.value
                                })
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-danger btn-sm text-white"
                              style={{ float: 'right', margin: '2px' }}
                              onClick={() => {
                                setSelectedComment(null)
                                resetComment()
                              }}
                            >
                              Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                style={{ float: 'right', margin: '2px' }}
                                disabled={newComment.content.length < 2}
                                onClick={() => {
                                  setSelectedComment(null)
                                  updateNodeComment(comment.id)
                                }}
                              >
                                <i className="bi bi-save2"></i> Update
                              </button>
                            </>
                        ) : (
                          <>
                            <p>
                            {comment.content}
                            </p>
  
                            <footer className="blockquote-footer">
                            <cite title="Source Title">{comment.createdBy}{' '}{comment.createdDate}</cite>
                            {
                              comment.createdBy === screenName ? (
                                <>
                                  <button
                                  type="button"
                                  className="btn btn-danger btn-sm text-white"
                                  style={{ float: 'right', margin: '2px' }}
                                  onClick={() => {
                                    deleteComment(comment)
                                  }}
                                >
                                  <i className="bi bi-trash"></i> Delete
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  style={{ float: 'right', margin: '2px' }}
                                  onClick={() => {
                                    setSelectedComment(comment)
                                    setShowNewForm(false)
                                    setNewComment({
                                      ...newComment,
                                      content: comment.content
                                    })
                                  }}
                                >
                                  <i className="bi bi-save2"></i> Edit
                                </button>
                              </>
                              ) : (null)
                            }
                          </footer>
                        </>
                        )}
                      </Card.Body>
                    </Card>
                  )) : null
                }
              </div>  
              {
                showNewForm ? (
                  
                  <Card style={{ margin: '3px' }}>
                    <Card.Body>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-12'>
                            <textarea 
                              value={newComment.content}
                              style={{ width: '100%' }}
                              onChange={(e) => {
                                setNewComment({
                                  ...newComment,
                                  content: e.target.value
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='bootstrap-modal-footer-button-alignment'>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm comment-button-stylization"
                              disabled={newComment.content.length < 2}
                              onClick={() => {
                                addNewComment()
                                resetComment()
                              }}
                            >
                              <i className="bi bi-save2"></i> Save
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm text-white comment-button-stylization"
                              onClick={() => {
                                setShowNewForm(false)
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ) : (null)
              }
            </Modal.Body>
            <Modal.Footer>
              <div className="container">
                <div className="row">
                  <div className="bootstrap-modal-footer-button-alignment">
                      <button
                        className="btn btn-primary btn-sm bootstrap-footer-button-stylization"
                        onClick={() => {
                          setSelectedComment(null)
                          resetComment()
                          setShowNewForm(true)
                        }}
                      >
                        Add Comment
                      </button>
                  
                      <button
                        className="btn btn-danger btn-sm text-white bootstrap-footer-button-stylization"
                        onClick={() => {
                          setSelectedComment(null)
                          resetComment()
                          setShow(false)
                        }}
                      >
                        Close
                      </button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
  return null;
};

InspectorTool.propTypes = {
  canvasMapData: PropTypes.object,
  selectedNode: PropTypes.object,
  selectionResources: PropTypes.object,
  show: PropTypes.bool,
  setShow: PropTypes.func,
};

export default InspectorTool;